const NFFLIST =[
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/1.png"
    },
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/2.png"
    },
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/3.png"
    },
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/4.png"
    },
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/5.png"
    },
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/6.png"
    },
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/7.png"
    },
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/8.png"
    },
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/9.png"
    },
    {
        "name": "Emiway Bantai x Celio",
    "description": "Celio X Emiway gives you access to [CelioVerse](https://celioverse.layer-e.com/) - the exclusive metaverse for Emiway KOTS merch and limited edition Emiway NFTs for KOTS. \n\n Check out [CelioVerse](https://celioverse.layer-e.com/) to meet Emiway in the metaverse.",

        "image": "https://ipfs.io/ipfs/QmWbLUzgpjLkz9GSFHJqJk5JXr8GmBSijxT9LLnWAJTVaG/10.png"
    },


]
export default NFFLIST;