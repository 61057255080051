import React from "react";
import styled from "styled-components";
import assets from "../../assets/images";

const DotBox = () => {
  return (
    <Dots>
      <div className="dotBox">
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
      </div>

      <div className="dotBox">
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
      </div>

      <div className="dotBox">
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>{" "}
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
        <img src={assets.logos.starDot} alt=""></img>
      </div>
    </Dots>
  );
};
const Dots = styled.div`
  width: 100%;
  overflow: hidden;
  .dotBox {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    img {
      min-width: 75px;
    }
  }
  @media screen and (max-width: 1310px) {
    .dotBox {
      gap: 30px;
    margin-bottom: 30px;
    img {
      min-width: 65px;
    }
  }

  }
@media screen and (max-width: 991px) {
  .dotBox {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
    img {
      min-width: 55px;
    }
  }

}
@media screen and (max-width: 768px) {
  margin-top: 10px;
  .dotBox {
    display: flex;
    gap: 17px;
    margin-bottom: 23px;
    img {
      min-width: 30px;
    }
  }


}
`;
export default DotBox;
