import "./App.css";
import { GlobalProvider } from "./Contexts/GlobalContext";
import { BrowserRouter as Router } from "react-router-dom";
import RoutWrapper from "./RoutWrapper";
import "@rainbow-me/rainbowkit/styles.css";

import { ThemeProvider } from "@0xsequence/design-system";
import "@0xsequence/design-system/styles.css";
import {
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { sequenceWallet } from "@0xsequence/rainbowkit-plugin";
import {
  metaMaskWallet,
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  // coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {  polygon } from "@wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
// import { alchemyProvider } from 'wagmi/providers/alchemy';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { SequenceConnector } from "@0xsequence/wagmi-connector";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";

function App() {
  const fpPromise = FingerprintJS.load();

  fpPromise
    .then((fp) => fp.get())
    .then((result) => {
      localStorage.setItem("secret", result.visitorId);
    });

  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [polygon],
    [publicProvider()]
    // alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
  );

  let connectors;
  // if (typeof window.ethereum !== "undefined") {
  connectors = connectorsForWallets([
    {
      groupName: "Recommended",
      wallets: [
        sequenceWallet({
          chains,
          connect: { app: "Demo-app", networkId: 137 },
          // shimDisconnect: true
        }),
        // metaMaskWallet({ chains, shimDisconnect: true }),
        // coinbaseWallet({ chains }),

        // rainbowWallet({ chains }),
        // walletConnectWallet({
        //   chains,
        //   projectId: 'cf87776b7b13aa1ef8c3a9dfe2fdc1c4',
        // }),
        injectedWallet({ chains, shimDisconnect: true }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
  });

  return (
    <ThemeProvider>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains}>
          <GlobalProvider>
            <Router>
              <ToastContainer />
              <RoutWrapper />
            </Router>
          </GlobalProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </ThemeProvider>
  );
}

export default App;
