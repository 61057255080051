import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import GlobalContext from "./Contexts/GlobalContext";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import ClaimPage from "./pages/ClaimPage/ClaimPage";
import ClaimCoupon from "./pages/ClaimCoupon/ClaimCoupon";
import PopUp from "./Components/PopUp/PopUp";
import { useContext, useEffect, useRef, useState } from "react";
import CalimReward from "./pages/CalimReward/CalimReward";
import ScrollToTop from "./Components/Utils/ScrollToTop";
import Loader from "./Components/Loader/Loader";
import MyNft from "./pages/MyNft/MyNft";
import MyCoupon from "./pages/MyCoupon/MyCoupon";
import TandC from "./pages/TandC/TandC";
import Task from "./pages/Task/Task";
import audio from "./assets/audio/adio.mp3";

// import ClaimPage from "./pages/ClaimPage/ClaimPage";

function RoutWrapper() {
  const { popup, isConnecting, isMuted, setIsMuted, userData } =
    useContext(GlobalContext);
  const location = useLocation();
  const audioRef = useRef(null);
  const [refirst, setrefirst] = useState(0);

  useEffect(() => {
    if (refirst <= 3) {
      setrefirst(refirst + 1);
    }
    var lIsmute;
    if (isMuted) {
      lIsmute = localStorage.getItem("mute");
    }

    // Play audio in a loop when the component is mounted
    audioRef.current.addEventListener("ended", () => {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    });

    audioRef.current.play();
    audioRef.current.muted = isMuted;
    audioRef.current.loop = true;

    // return () => {
    //   audioRef.current.removeEventListener('ended', () => {
    //     audioRef.current.currentTime = 0;
    //     audioRef.current.play();
    //   });
    // };

    // Cleanup
  });
  return (
    <>
      {isConnecting ? <Loader /> : null}
      <div className="App">
        <Navbar />
        <audio ref={audioRef}>
          <source src={audio} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        {popup?.status ? <PopUp /> : null}
        <ScrollToTop>
          <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/claim" element={<ClaimPage />} />
            <Route path="/claim/:state" element={<CalimReward />} />
            <Route path="/task/:type" element={<Task />} />

            <Route path="/claim-coupon" element={<ClaimCoupon />} />
            <Route path="/mycollectibles" element={<MyNft />} />
            <Route path="/coupons" element={<MyCoupon />} />
            <Route path="/terms-and-conditions" element={<TandC />} />
          </Routes>
        </ScrollToTop>
        {location.pathname !== "/terms-and-conditions" && <Footer />}
      </div>
    </>
  );
}

export default RoutWrapper;
