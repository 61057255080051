import { useContext } from "react";
import GlobalContext from "../../Contexts/GlobalContext";
import Button from "../Utils/Button/Button";
import "./ProductCard.css";


const ProductCard = ({ product }) => {
  const { minted, isclaimed, setPopUp } = useContext(GlobalContext);

  const discound = product?.price - product?.price * (20 / 100);
  const showpop = (url)=>{
   
    if(!minted){
    setPopUp({
      status: true,
      data: {
        condent:
          "We see you have great taste!         ",
          subContent: "Did you know fans can now unlock great discounts by claiming their fan collectible? ",
        bbuttonText: "Claim collectible",
        bbtn: true,
        copyBtn: false,
        newTab: false,
        baction: "/claim",
        btn2Text: "Buy now ",
        btn2action: url,
        butn2: true,
      },
    })
  }
  }

  return (
    <div className="product__outer" >
      <div className="product__item">
        <div className="product__image" onClick={()=>{showpop(product.url)}}>
          <img src={product.productImage} alt={product.productName} />
        </div>

        <div className="product__info">
          <div className="product__info__inner">
          <div className="tooltip"><span className="tooltiptext">{product.productName}</span>
            <p className="item__company">{product.productName.slice(0,20) }{ product.productName.length >= 20 ?"..." :null}</p>
            </div>
         {/*   <p className="item__name">{product.productCompany}</p>*/}
            {isclaimed ? (
              <div className="discoundedPrice">
              
                <div className="item__company price ">₹{product.price}</div>
               
              </div>
            ) : (
              <p className="item__company price">₹{product.price}</p>
              
            )}
          </div>
        </div>
      </div>
      {minted ? (
        <div className="button__container">
          <div
            onClick={() =>
              isclaimed && minted
                ? setPopUp({
                    status: true,
                    data: {
                      condent:
                        "Unlock amazing discounts right away!",
                      buttonText: "#EMC948JD",
                      copyBtn: true,
                      newTab: false,

                      action: 'null',
                      url: product?.url
                    },
                  })
                : setPopUp({
                    status: true,
                    data: {
                      condent:
                        "Avail the coupon to get amazing discounts  right away!",
                      buttonText: "Claim Coupon",
                      copyBtn: false,
                      newTab: false,
                      action: "/claim-coupon",
                    },
                  })
            }
          >
            <Button text="Buy Now!" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductCard;
