import React, { useState, createContext, useEffect, useRef } from "react";
import Web3 from "web3";
import { ABI, ADDRESS } from "../assets/ABI/ABI";
import axios from "axios";
import { usePublicClient, useAccount } from "wagmi";
import { SequenceIndexerClient } from "@0xsequence/indexer";

const GlobalContext = createContext({});
export const GlobalProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { isConnected, address } = useAccount();
  const [signature, setSignature] = useState();
  const [chainId, setChainId] = useState();
  const [isMinitng, setisMinitng] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [minted, setMinted] = useState(false);
  const [isclaimed, setisClaimed] = useState(false);
  const [shoPre, setShowPre] = useState(false);
  const [vedioPaly, setVedioPlay] = useState(false);
  const [userData, setUserData] = useState(false);
  const [discoundScore, setDiscoundScore] = useState(25);
  const [indexedNFt, setindexedNFt] = useState();
  const [web3Available, setWeb3Available] = useState(false);
  const [isMuted, setMuted] = useState(false);
  const audioRef = useRef(null);


  const [popup, setPopUp] = useState({
    status: false,
    data: {
      condent: "",
      buttonText: "",
      action: "",
    },
  });

  const indexer = new SequenceIndexerClient(
  "https://polygon-indexer.sequence.app"

  );
  // "https://mumbai-indexer.sequence.app"

  const contractAddress = ADDRESS;
  const accountAddress = address;

  // const BE = "http://localhost:5000";
  const BE = "https://celio.up.railway.app";

  // const web3 = new Web3(magic.rpcProvider);
  const web3 = new Web3(publicClient);
  const NFTContract = new web3.eth.Contract(ABI, ADDRESS);
  // console.log(userData, "-------------------");
  useEffect(() => {
    var walletT = localStorage.getItem("wagmi.wallet");
    if (typeof window.ethereum !== "undefined") {
      if (isConnected && walletT === "injected") {
        switchNetwork();
      }
      setWeb3Available(true);
    } else {
      setWeb3Available(false);
    }

    var fistTime = localStorage.getItem("onboard");
    
    if (isConnected ) {
      
      
      user();
      checkMintedOrNot();
      indexNFT();

      if (walletT === "injected") {
        // switchNetwork();
      }

      // findDiscont()
    }
    if(isConnected && !fistTime){
      axios
      .post(`${BE}/create`, {
        wallet: address,
        walletType: walletT.replace( /"/g, "" )

      })
      .then((res) => {
        // mint();
      })
      .catch((err) => {
        console.log(err);
        // mint();
        // if (err.response.status === 401) {
        //   // toast.error("Bot Attempt detected !");
        // }
      });
    }
    // eslint-disable-next-line
  }, [address, isConnected, setMinted, minted, setindexedNFt]);

  const indexNFT = async () => {
    const nftBalances = await indexer.getTokenBalances({
      contractAddress: contractAddress,
      accountAddress: accountAddress,
      includeMetadata: true,
    });

    setindexedNFt(nftBalances);
  
  };
  const user = async () => {
    await axios
      .get(`${BE}/user?wallet=${address}`)
      .then((res) => {
        setUserData(res.data.data);
        setDiscoundScore(res.data.data.TaskDone);
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkMintedOrNot = async () => {
    await axios
      .get(`${BE}/isMinted?wallet=${address}`)
      .then((res) => {
        if (res.data.data.isMinted) {
          setMinted(true);
        }
        if (res.data.data.claimed) {
          setisClaimed(true);
        }
        if (res.data.data.isMintedPreview) {
          setShowPre(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function fetchAccountData() {
    try {
      const getNFTContract = new web3.eth.Contract(ABI, ADDRESS);
      if (address) {
        const nftHole = await getNFTContract.methods
          .balanceOf(address, 1)
          .call();

        if (nftHole >= 1) {
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const switchNetwork = async () => {
    if (window.ethereum && isConnected) {
      try {
        // Try to switch to the Mumbai testnet
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // Check networks.js for hexadecimal network ids
        });
      } catch (error) {
        // This error code means that the chain we want has not been added to MetaMask
        // In this case we ask the user to add it to their MetaMask
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x89",
                  chainName: "Polygon Mainnet",
                  rpcUrls: ["https://polygon.llamarpc.com"],
                  nativeCurrency: {
                    name: "MATIC",
                    symbol: "MATIC",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://polygonscan.com/"],
                },
              ],
            });
          } catch (error) {
            console.log(error);
          }
        }
        console.log(error);
      }
    } else {
      // If window.ethereum is not found then MetaMask is not installed
      // alert(
      //   "MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html"
      // );
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        address,
        signature,
        setSignature,
        isConnected,
        chainId,
        setChainId,
        isclaimed,
        setisClaimed,
        web3,
        isMinitng,
        setisMinitng,
        fetchAccountData,
        NFTContract,
        audioRef,
        popup,
        setPopUp,
        minted,
        setMinted,
        isConnecting,
        setIsConnecting,
        BE,
        checkMintedOrNot,
        shoPre,
        setShowPre,
        vedioPaly,
        setVedioPlay,
        userData,
        discoundScore,
        setDiscoundScore,
        user,
        indexedNFt,
        setindexedNFt,
        indexNFT,
        web3Available,
        setWeb3Available,
        isMuted, setMuted,

        // discoundScore,
        // setDiscoundScore,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
