export const twitterSteps = {
  Heading: "Twitter",
  taskType: "twitter",
  initaial: {
    title: "Enjoy additional discount",
    discription:
      "Get immediate additional discount and a chance to be picked by Emiway for a shoutout and free merchandise.",
    button: {
      buttonText: "Enter contest",
      action: "",
    },
  },
  steps: [
    {
      title: "Download collectible",
      discription:
        "Download your unique NFT and share it on Twitter to show off your Emiway Merch with pride. ",
      button: [
        {
          buttonText: "Download",
          action: "download",
        },
        {
          buttonText: "Next",
          action: "next",
        },
      ],
      step: 1,
    },
   
    {
      title: "Post on Twitter",
      discription:
        "Post the collectible on Twitter and write a caption about Emiway’s picture.  Tag @emiwaybantai and @celioindia and post. Come back to this page once done.",
      button: [
        {
          buttonText: "Share",
          action: "share",
        },
        {
          buttonText: "Next",
          action: "next",
        },
      ],
      step: 2,
    },
    // {
    //   title: "Verify Twitter account",
    //   discription:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non",
    //   button: [
    //     {
    //       buttonText: "Verify",
    //       action: "verify",
    //     },
    //   ],
    //   step: 3,
    // },
    {
      title: "Verify Twitter account",
      discription:
        "Submit your username for verification and let us confirm your post to complete the task and receive your discount!",
      input: true,
      step: 3,
    },
  ],
};
export const twitterStepsold = {
  Heading: "Twitter",
  taskType: "twitter",
  initaial: {
    title: "Enjoy additional discount",
    discription:
      "Get immediate additional discount and a chance to be picked by Emiway for a shoutout and free merchandise.",
    button: {
      buttonText: "Enter contest",
      action: "",
    },
  },
  steps: [
    {
      title: " connect your twitter",
      discription:
        "Connect your Twitter account to share your freshly minted NFT and spread the word. Once you hit the button below you will get redirected to the Tweet Now screen and you can share your excitement with the world!        ",
      button: [
        {
          buttonText: "connect your twitter",
          action: "auth",
        }
       
      ],
      step: 1,
    },
    {
      title: "Post on Twitter",
      discription:
        "Post the collectible on Twitter and write a caption about Emiway’s picture.  Tag @emiwaybantai and @celioindia and post. Come back to this page once done.",
      button: [
        {
          buttonText: "Share",
          action: "share",
        },
        {
          buttonText: "Next",
          action: "next",
        },
      ],
      step: 2,
    },
    {
      title: "Verify Twitter account",
      discription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non",
      button: [
        {
          buttonText: "Verify",
          action: "verify",
        },
      ],
      step: 3,
    },
  ],
};

export const instagramSteps = {
  Heading: "Instagram ",
  taskType: "instagram",
  initaial: {
    title: "Enjoy additional discount",
    discription:
      "Get immediate additional discount and a chance to be picked by Emiway for a shoutout and free merchandise.",
    button: {
      buttonText: "Enter contest",
      action: "",
    },
  },
  steps: [
    {
      title: "Download collectible",
      discription:
        "Download your unique NFT and share it on Instagram to show off your Emiway Merch with pride. ",
      button: [
        {
          buttonText: "Download",
          action: "download",
        },
        {
          buttonText: "Next",
          action: "next",
        },
      ],
      step: 1,
    },
    {
      title: "Post on Instagram",
      discription:
        "Post the collectible on Instagram and write a caption about Emiway’s picture.  Tag @emiwaybantai and @celioindia and post. Come back to this page once done.",
      button: [
        {
          buttonText: "Share",
          action: "share",
        },
        {
          buttonText: "Next",
          action: "next",
        },
      ],
      step: 2,
    },
    {
      title: "Verify Instagram account",
      discription:
        "Submit your username for verification and let us confirm your post to complete the task and receive your discount!",
      input: true,
      step: 3,
    },
  ],
};

export const facebookSetp = {
  Heading: "Facebook ",
  taskType: "facebook",
  initaial: {
    title: "Enjoy additional discount",
    discription:
      "Get immediate additional discount and a chance to be picked by Emiway for a shoutout and free merchandise.",
    button: {
      buttonText: "Enter contest",
      action: "",
    },
  },
  steps: [
    {
      title: "Download collectible",
      discription:
        "Connect your Facebook account to share your one-of-a-kind NFT and spread the Emiway Merch excitement. ",
      button: [
        {
          buttonText: "Download",
          action: "download",
        },
        {
          buttonText: "Next",
          action: "next",
        },
      ],
      step: 1,
    },
    {
      title: "Post on Facebook",
      discription:
        "Post the collectible on Instagram and write a caption about Emiway’s picture.  Tag @emiwaybantai and @celioindia and post. Come back to this page once done.",
      button: [
        {
          buttonText: "Share",
          action: "share",
        },
        {
          buttonText: "Next",
          action: "next",
        },
      ],
      step: 2,
    },
    {
      title: "Verify Facebook account",
      discription:
        "Submit your username for verification and let us confirm your post to complete the task and receive your discount!",
      input: true,
      step: 3,
    },
  ],
 
};

export const whatsappSteps = {
  Heading: "Whatsapp ",
  taskType: "whatsapp",
  initaial: {
    title: "Enjoy additional discount",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non",
    button: {
      buttonText: "Enter contest",
      action: "",
    },
  },
  steps: [
    {
      title: "Join our community",
      discription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non",
      button: [
        {
          buttonText: "Join whatsapp community",
          action: "share",
        },
        {
          buttonText: "Next",
          action: "next",
        },
      ],
      step: 1,
    },
    {
      title: "Verify Whatsapp Number",
      discription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non",
      input: true,

      step: 2,
    },
  ],
};
