import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import assets from "../../assets/images";
import DotBox from "../../Components/DotBox/DotBox";

import GlobalContext from "../../Contexts/GlobalContext";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import FAQ from "../../Components/FAQ/FAQ";
import boxVedios from "../../assets/videos/CRATE.webm";
import Button from "../../Components/Utils/Button/Button";
import Marquee from "../../Components/Marquee/Marquee";

const MyCoupon = () => {
  const { isclaimed, isConnected, userData } = useContext(GlobalContext);

  const [isCopied, setIsCopied] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (isclaimed === false || isConnected === false) {
      navigate("/");
    }
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  }, [isCopied, isclaimed, isConnected]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, height: "auto", transition: { duration: 0.8 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <CouponWrapper>
        <div className="ff">
          <div className="section">
            <div className="container">
              <div className="navigation">
                <img
                  style={{ cursor: "pointer" }}
                  src={assets.logos.arrow}
                  alt=""
                  onClick={() => navigate(-1)}
                />
                <div className="naveText">My coupons</div>
              </div>
              <div className="myCard">
                <div className="left">
                  <video
                    autoPlay
                    playsInline
                    loop
                    muted
                    src={boxVedios}
                  ></video>
                </div>
                <div className="right">
                  <div className="rightTop">
                    <div className="heading">
                    ₹{userData?.couponAmount} discount unlocked on Emiway Bantai merch
                    </div>
                    <div className="par">
                    Congratulations on getting a Rs. {userData?.couponAmount} discount on the Merch. You can use this coupon code directly on the Celio website when you are checking out.
                    </div>
                  </div>
                  {isCopied ? (
                    <Button text={"Copied"} />
                  ) : (
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(userData?.couponCode);
                        setIsCopied(true);
                      }}
                    >
                      <div className="righyBottom">
                        <Button
                          text={userData?.couponCode}
                          imgafter={assets.images.copy}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mark">
          <Marquee />
        </div>

        <div className="faqa">
          <FAQ />
        </div>
        <DotBox />
      </CouponWrapper>
    </motion.div>
  );
};

const CouponWrapper = styled.div`
  .ff {
    position: relative;
    position: relative;
    margin-top: 60px;
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pip {
    position: absolute;
    right: -50px;
    top: -120px;
    width: 500px;
  }
  .roll {
    position: absolute;
    left: -100px;
    bottom: -80px;
    width: 500px;
  }
  .faqa {
    margin-bottom: 60px;
  }
  .mark {
    margin-bottom: 80px;
  }
  .section {
    width: 100%;
    padding: 60px;
    /* min-height: 600px; */
  }
  .navigation {
    display: flex;
    font-size: 36px;
    line-height: 100%;
    font-weight: 800;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
  }
  .myCard {
    margin-top: 50px;
    background: rgba(37, 37, 37, 0.9);
    border: 1px solid #c4c4c4;
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 24px;
    display: flex;
    padding: 35px;
    gap: 24px;

    .left {
      video {
        width: 300px;
      }
    }
    .right {
      padding-top: 20px;
      padding-bottom: 20px;
      .rightTop {
      }

      .righyBottom {
        margin-top: 50px;
      }
      .heading {
        font-family: "Trap";
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 100%;
      }
      .par {
        font-weight: 500;
        font-size: 22px;
        line-height: 130%;
      }
    }
  }
  @media screen and (max-width: 998px) {
    .myCard {
      flex-direction: column;

      .left {
        video {
          margin: 0 auto;
          width: 350px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .righyBottom {
          margin: 0 auto;
          margin-top: 50px;
        }
        .heading {
          font-family: "Trap";
          font-style: normal;
          font-weight: 800;
          font-size: 26px;
          line-height: 100%;
          margin-bottom: 20px;
        }
        .par {
          font-weight: 500;
          font-size: 19px;
          line-height: 130%;
        }
      }
    }
  }
  @media screen and (max-width: 760px) {
    .pip {
      position: absolute;
      right: -30px;
      top: -20px;
      width: 220px;
    }
    .roll {
      position: absolute;
      left: -70px;
      bottom: -50px;
      width: 200px;
    }

    .section {
      width: 100%;
      padding: 00px 0px;
    }

    .navigation {
      margin-top: 60px;
      display: flex;
      font-size: 24px;
      line-height: 100%;
      font-weight: 800;
      gap: 15px;
      justify-content: flex-start;
      align-items: center;
      z-index: 2;
      position: relative;
      img {
        width: 25px;
      }
    }
    .myCard {
      margin-top: 30px;

      flex-direction: column;
      padding: 20px;
      margin-bottom: 60px;

      .left {
        video {
          margin: 0 auto;
          width: 90%;
        }
        img {
          width: 90%;
          margin: 0 auto;
        }
      }
      .right {
        padding-top: 20px;
        padding-bottom: 20px;
        /* text-align: center; */
        text-align: left;

        .rightTop {
        }

        .righyBottom {
          display: flex;
          justify-content: center;
          margin-top: 30px;
        }
        .heading {
          font-family: "Trap";
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          text-align: left;

          line-height: 120%;
        }
        .par {
          font-weight: 500;
          font-size: 14px;
          line-height: 130%;
          margin-top: 15px;
        }
      }
    }
  }
`;
export default MyCoupon;
