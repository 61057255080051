import React from "react";
import styled from "styled-components";
import assets from "../../assets/images";
import Button from "../Utils/Button/Button";

const Biome = () => {
  return (
    <BiomeWrapper>
      <img src={assets.images.wdaibent} className="biomlefttimg vert-move-reverse " alt="" />
      <img src={assets.images.cds} className=" biomrightimg vert-move " alt="" />

      <div className="container">
        <div className="heading">
        Visit <span className="yellow">Emiway's Pad</span>   in the celioverse,
        </div>
        <div className="biomPreview">
          <img src={assets.images.biome} alt="" />
        </div>
        <div className="btnSec">
          <div className="center">
            <Button text={"Experience the celioverse"} href="http://celioverse.layer-e.com/" />
          </div>
        </div>
      </div>
    </BiomeWrapper>
  );
};
const BiomeWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: 80px;

  .biomrightimg {
    position: absolute;
    right: -200px;
    top: -110px;
    width: 450px;
  }
  .biomlefttimg {
    position: absolute;
    left: -110px;
    bottom: -170px;
    width: 360px;
  }
  .biomPreview {
    margin: 0 auto;

    margin-top: 54px;
    margin-bottom: 40px;
    width: 800px;

    img {
      width: 100%;
    }
  }
  .btnSec {
    display: block;
    width: 100%;
    .center {
      margin: 0 auto;
      width: max-content;
    }
  }
  @media screen and (max-width: 999px) {
    .biomPreview {
      margin: 0 auto;

      margin-top: 54px;
      margin-bottom: 40px;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 40px;

    .biomrightimg {
      right: -100px;
      top: 10px;
      width: 200px;
      rotate: 60deg;
    
    }
    .biomlefttimg {
      left: -20px;
      bottom: -100px;
      width: 120px;
      display: none;
    }
    .biomPreview {
      margin-top: 32px;
      margin-bottom: 32px;
      position: relative;
      z-index: 20;
    }
    .biomPreview {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
`;
export default Biome;
