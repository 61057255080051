import React, { useContext, useRef } from "react";
import styled from "styled-components";
import assets from "../../assets/images";
import GlobalContext from "../../Contexts/GlobalContext";
import Button from "../Utils/Button/Button";
import { motion } from "framer-motion";

const PopUp = () => {
  const { popup, setPopUp } = useContext(GlobalContext);

  function handleClickOutside(event) {
    setPopUp(false);
  }
  function handleInnerClick(event) {
    event.stopPropagation();
  }

  const copyBTn = () => {
    const rurl = popup.data.url

    setPopUp({
      status: true,
      data: {
        condent: "Avail the coupon to get amazing discounts  right away!",
        buttonText: "Buy Now",
        copyBtn: false,
        newTab: true,

        action: rurl,
      },
    });
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0 } }}
      exit={{ opacity: 0, transition: { duration: 0.0 } }}
    >
      <PopUpWrapper onClick={handleClickOutside}>
        <div className="s">
          <div className="container">
            <div className="popupCard" onClick={handleInnerClick}>
              <div className="wrapperYelloe" style={{ overflow: "hidden" }}>
                <div className="yellowFade pos"></div>
                

                {popup.data.task ? null : (
                  <div className="closeBtn" onClick={() => setPopUp(false)}>
                    <svg
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.8174 2.56067L17.8951 0.706055L10.2741 8.05876L2.65302 0.706055L0.730713 2.56067L8.35176 9.91337L0.730713 17.2661L2.65302 19.1207L10.2741 11.768L17.8951 19.1207L19.8174 17.2661L12.1964 9.91337L19.8174 2.56067Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <div className="popupWrapperBox">
                <div className="subhead mar">{popup?.data?.condent}</div>
                <div className="subhead mars">{popup?.data?.subContent}</div>

                {popup?.data?.form || popup?.data?.task ? (
                  <div className="SuceesIcon">
                    <img src={assets.images.done} alt="" />
                  </div>
                ) : popup?.data?.copyBtn ? (
                  <Button
                    text={popup?.data?.buttonText}
                    imgafter={assets.images.copy}
                    click={copyBTn}
                  />
                ) : popup?.data.newTab ? (
                  <Button
                    text={popup?.data?.buttonText}
                    href={popup?.data?.action}
                  />
                ) :popup?.data?.bbtn?null  :  (
                  
                  <Button
                    text={popup?.data?.buttonText}
                    link={popup?.data?.action}
                  />
                  

                )}
                {!popup?.data?.butn2  ? null : (
                  <div className="btnMNo">
                
                 
               
                  <Button
                    text={popup?.data?.btn2Text}
                    href={popup?.data?.btn2action}
                    
                  />
                   <Button
                    text={popup?.data?.bbuttonText}
                    link={popup?.data?.baction}
                  />
                  </div>

                )}
                {popup?.data?.form || popup?.data?.task || popup?.data?.butn2 ? null : (
                  <div
                    className="notNowBtn"
                    onClick={() => setPopUp({ status: false })}
                  >
                    Not now
                  </div>
                )}
                {popup?.data?.task ? (
                  <Button
                    text={popup?.data?.buttonText}
                    link={popup?.data?.action}
                  />
                ) : null}
              </div>
            </div>{" "}
          </div>{" "}
        </div>
      </PopUpWrapper>
    </motion.div>
  );
};
const PopUpWrapper = styled.div`
.btnMNo{
  display: flex; 
  gap:30px;
  margin-top:50px !important;

  .button{
    margin-top:-20px !important;
  }
}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  .s {
    width: 100%;
  }
  .closeBtn {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .wrapperYelloe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mar {
    margin-bottom: 60px;
    position: relative;
    font-size: 44px;
    z-index: 3;
  }
  .mars {
    margin-bottom: 10px;
    position: relative;
    font-size: 32px;
    z-index: 3;
  }
  .SuceesIcon {
    position: relative;
    z-index: 3;
    img {
      width: 300px;
    }
  }
  .popupCard {
    overflow: hidden;
    background: rgba(37, 37, 37, 0.9);
    border: 1px solid #c4c4c4;
    backdrop-filter: blur(10px);

    position: relative;
    border-radius: 24px;
    width: inherit;
  }
  .popupWrapperBox {
    padding: 70px 60px;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
  }
  .notNowBtn {
    cursor: pointer;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    text-decoration: underline;
    cursor: pointer;
    z-index: 3;
  }
  @media screen and (max-width: 1358px) {
    .popupWrapperBox {
      padding: 0px 60px;
      padding-top: 50px;
      padding-bottom: 40px;
    }
    .mar {
      margin-bottom: 40px;
      max-width: 80%;
      position: relative;
      font-size: 36px;
      z-index: 3;
    }
    .mars {
      margin-bottom: 10px;
      max-width: 80%;
      position: relative;
      font-size: 28px;
      z-index: 3;
    }

    .SuceesIcon {
      position: relative;
      z-index: 3;
      img {
        width: 250px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .btnMNo{
  display: flex; 
  flex-direction: column;
  gap:15px;
  margin-top:50px !important;

  .button{
    margin-top:-20px !important;
  }
}
    .pos {
      background: #ebc43e;
      filter: blur(178.9px);
      width: 217px;
      height: 217px;
    }
    .SuceesIcon {
      img {
        width: 200px;
      }
    }

    .popupWrapperBox {
      padding: 70px 10px;
      display: flex;
      justify-items: center;
      align-items: center;
      flex-direction: column;
    }
    .closeBtn {
      position: absolute;
      right: 20px;
      top: 22px;
      cursor: pointer;
      svg {
        width: 15px;
        height: 15px;
      }
    }
    .mar {
      margin-bottom: 20px;
      max-width: 90%;

      font-size: 18px;
    }
    .mars {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
    }
    .notNowBtn {
      cursor: pointer;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      text-decoration: underline;
      cursor: pointer;
      z-index: 3;
    }
  }
`;

export default PopUp;
