import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Home.css";
import { products } from "../../assets/database/products";
import ProductCard from "../../Components/ProductCard/ProductCard";

import { useContext, useEffect, useState, useRef } from "react";

import GlobalContext from "../../Contexts/GlobalContext";
import assets from "../../assets/images";
import Button from "../../Components/Utils/Button/Button";
import Marquee from "../../Components/Marquee/Marquee";
import Biome from "../../Components/Biome/Biome";
import DropBox from "../../Components/DropBox/DropBox";
import FAQ from "../../Components/FAQ/FAQ";
import DotBox from "../../Components/DotBox/DotBox";
import BgCard from "../../Components/BgCard/BgCard";
import boxVedios from "../../assets/videos/CRATE.webm";
import { motion } from "framer-motion";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const Home = () => {
  const helloRef = useRef(null);
  const { openConnectModal } = useConnectModal();
  const {
    minted,
    isclaimed,
    isConnected,
    connect,
    checkMintedOrNot,
    userData,
    user,
    setPopUp,
  } = useContext(GlobalContext);
  const [isCopied, setIsCopied] = useState(false);

  const btnFun = () => {
    if (isclaimed) {
      return (
        <>
          {isCopied ? (
            <Button text={"Copied"} />
          ) : userData ? (
            <div
              onClick={() => {
                navigator.clipboard.writeText(userData?.couponCode);
                setIsCopied(true);
              }}
            >
              <Button
                text={userData?.couponCode}
                imgafter={assets.images.copy}
              />
            </div>
          ) : null}

          <Button
            text={"Buy Now"}
            href={"https://www.celio.in/do-it-your-way.list"}
          />
        </>
      );
    }
    // else if (isclaimed) {
    //   return (
    //     <Button
    //       text={"Join Whatsapp Community"}
    //       imgbefore={assets.logos.wplogo}
    //     />
    //   );
    // }
    else {
      return <Button text={"Claim Coupon"} link="/claim-coupon" />;
    }
  };
  const yourFunction = () => {
    const checkInitial = localStorage.getItem("onboard");
    if (!checkInitial) {
      localStorage.setItem("onboard", true);
      setPopUp({
        status: true,
        data: {
          condent:
            "Fans get a chance to unlock great discounts on the Emiway merch. ",
          subContent: "Claim your fan collectible to unlock discounts.  ",
          buttonText: "Claim collectible",
          copyBtn: false,
          newTab: false,
          action: "/claim",
          btn2Text: "Buy now ",
        },
      });
    }
  };
  const inactive = () => {
  
      if (!userData?.isMinted) {
       
        setPopUp({
          status: true,
          data: {
            condent:
              "Fans get a chance to unlock great discounts on the Emiway merch. ",
            subContent: "Claim your fan collectible to unlock discounts.  ",
            buttonText: "Claim collectible",
            copyBtn: false,
            newTab: false,
            action: "/claim",
            btn2Text: " ",
          },
        });
      }
  
  };
 

  const inactiveTimerRef = useRef(null);
  const inactiveTimeout = 5000;

  useEffect(() => {
    checkMintedOrNot();
    user();
    btnFun();

    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
          // Call your function here
          yourFunction();
        }
      });
    };
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (helloRef.current) {
      observer.observe(helloRef.current);
    }

 

    return () => {
      if (helloRef.current) {
        observer.unobserve(helloRef.current);
      }

    };
    // eslint-disable-next-line
  }, [isConnected, setIsCopied, isCopied]);
  // useEffect(() => {
  //   const handleUserActivity = () => {
  //     clearTimeout(inactiveTimerRef.current);

  //     // Reset the timer
  //     inactiveTimerRef.current = setTimeout(() => {
  //       // Call your function here
  //       inactive();
  //     }, inactiveTimeout);
  //   };

  //   const handleUserInactive = () => {
      // handleUserActivity();
  //   };

  //   // Add event listeners to track user activity
  //   window.addEventListener('mousemove', handleUserInactive);
  //   window.addEventListener('keydown', handleUserInactive);

  //   // Initial start of the timer
  //   handleUserActivity();

  //   // Cleanup
  //   return () => {
  //     clearTimeout(inactiveTimerRef.current);
  //     window.removeEventListener('mousemove', handleUserInactive);
  //     window.removeEventListener('keydown', handleUserInactive);
  //   };
  // }, []);
  const audioRef = useRef(null);

  

  return (
    <div style={{ overflow: "hidden" }}>
   
      <section className="">
     
        {minted ? (
          <>
            <div className="climeHead ">
              <div className="headWrapper container">
                <div className="left">
                  <div className="heading">
                    Hey <span className="yellow">There! </span>
                  </div>
                </div>
                <div className="right"></div>
              </div>
            </div>
            <div className="container" style={{ marginBottom: "80px" }}>
              <div className="pc">
                <BgCard>
                  <div className="totalwrapper">
                    <div className="leftSide">
                      <div className="yellowFade postioncard"></div>

                      <div className="dotBoxSmall">
                        <img src={assets.images.dots} alt="" />
                      </div>
                      <div className="dropbox">
                        <div className={"homebox"}>
                          <video
                            autoPlay
                            playsInline
                            loop
                            muted
                            src={boxVedios}
                          ></video>
                        </div>
                      </div>
                    </div>
                    <div className="rightSide">
                      <div className="maintTextBox">
                        <div className="textsBoxs">
                          Congratulations on claiming the collectible. What
                          next?
                        </div>
                      </div>
                      <div className="smalltest">
                        Now you can unlock the chest to claim coupons and take
                        part in contests.
                      </div>
                      <div className="buttonBox">{btnFun()}</div>
                    </div>
                  </div>
                </BgCard>
              </div>
              <div className="mobile">
                <div className="totalwrapper">
                  <div className="rightSide">
                    <div className="maintTextBox">
                      <div className="textsBoxs">
                        🎉 Congratulations on claiming the collectible. What
                        next?
                      </div>
                    </div>
                    <div className="smalltest">
                      Now you can unlock the chest to claim coupons and take
                      part in contests.
                    </div>
                    <div className="leftSide">
                      <div className="yellowFade postioncard"></div>

                      <div className="dotBoxSmall">
                        <DotBox />
                      </div>
                      <div className="dropbox">
                        <div className={"homebox"}>
                          <video
                            autoPlay
                            playsInline
                            loop
                            muted
                            src={boxVedios}
                          ></video>
                        </div>
                      </div>
                    </div>
                    <div className="buttonBox">
                      {isclaimed ? (
                        <>
                          {isCopied ? (
                            <Button text={"Copied"} />
                          ) : (
                            <div
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  userData?.couponCode
                                );
                                setIsCopied(true);
                              }}
                            >
                              <Button
                                text={userData?.couponCode}
                                imgafter={assets.images.copy}
                              />
                            </div>
                          )}

                          <Button
                            text={"View Collections"}
                            href={"https://www.celio.in/do-it-your-way.list"}
                          />
                        </>
                      ) : (
                        <Button text={"Claim Coupon"} link="/claim-coupon" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="yellowFade postion"></div>

            <div className="climeHead ">
              <div className="headWrapper container">
                <div className="left">
                  <div className="heading">
                    Be a{" "}
                    <span className="yellow">
                      Creator<br></br> Collector{" "}
                      <svg
                        className="dotTest"
                        viewBox="0 0 65 63"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25 0V22.68L5 15.12L0 27.72L22.5 35.28L7.5 55.44L20 63L32.5 42.84L47.5 63L57.5 55.44L42.5 35.28L65 27.72L60 15.12L37.5 22.68V0L25 0Z"
                          fill="#F8F8F8"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="right">
                  <div className="text">
                    Crates of creator merch, designed just for fans like you
                  </div>

                  <div className="">
                    {isConnected ? (
                      <Button text={"Claim Collectible"} link={"/claim"} />
                    ) : (
                      <Button
                        text={"Claim Collectible"}
                        click={openConnectModal}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="nftPreview">
              <motion.div className="rightDiaOverlay vert-move-reverse">
                <img src={assets.images.sharp} alt="" />
              </motion.div>
              <img
                src={assets.images.shape}
                alt=""
                className="rightsOverlay vert-move "
              />

              <div className="nftCard">
                <img src={assets.images.nft} alt="" />
              </div>
              <div className="bg-wrapperNft">
                <div className="emiwayText">EMIWAY BANTAI</div>
                <DotBox />
              </div>
            </div>
          </>
        )}
       
        <Marquee />
      </section>

      <div ref={helloRef} className="bgwrapper">
        <section className="products">
          <img
            src={assets.images.circle}
            alt=""
            className="leftcircleOverlay vert-move "
          />
          <div className="container">
            <div className="heading">
              Exclusive <span className="yellow">Emiway Bantai</span> Merch{" "}
            </div>
          </div>
          <div className=" container">
            <div className="cards__container " id="view-collections">
              <div className="cards__row">
                {products.map((product, index) => (
                  <ProductCard product={product} key={index} />
                ))}
              </div>
              <div className="btnSec">
                {minted ? null : (
                  <div>
                    <Button text={"Claim collectible"} link={"/claim"} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="innerBox">
        <Biome />
      </div>
      <section>
        <DropBox />
      </section>
      <section>
        <FAQ />
      </section>
      <DotBox />
    </div>
  );
};

export default Home;
