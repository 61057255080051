import Button from "../Utils/Button/Button";
import "./Footer.css";
import assets from "../../assets/images";
import { useContext, useRef, useState } from "react";
import BgCard from "../BgCard/BgCard";
import GlobalContext from "../../Contexts/GlobalContext";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Footer = () => {
  const { setPopUp } = useContext(GlobalContext);

  const [form, setForm] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [termAndCondition, setTermAndCondition] = useState(false);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [termError, setTermError] = useState("");
  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check if name input is empty
    if (name.trim() === "") {
      setNameError("Name is required");
    } else {
      setNameError("");
    }

    // Check if email input is empty or invalid
    if (email.trim() === "") {
      setEmailError("Email is required");
    } else if (!isValidEmail(email.trim())) {
      setEmailError("Email is invalid");
    } else {
      setEmailError("");
    }
    if (termAndCondition === false) {
      setTermError("check in the box to accept the terms and conditions ");
    } else {
      setTermError("");
    }

    if (
      name.trim() !== "" &&
      isValidEmail(email.trim()) &&
      termAndCondition !== false
    ) {
      // Do something with the form data, e.g. send it to a server

      let bodyFormData = new FormData();
      bodyFormData.append("Email", email?.toLowerCase());
      bodyFormData.append("Name", name);

      try {
        setFormLoader(true);
        const res = await axios({
          method: "post",
          url: "https://script.google.com/macros/s/AKfycbzZVw62NRdSit5p8ZV5xc1jJeZign3iwvvou_n5WQKIbXqrQohKu49Q4XGxT-EiMB4b/exec",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (res.status === 200) {
          setPopUp({
            status: true,
            data: {
              condent:
                "Thanks for sending the information across ! Stay excited to get our updates  ",
              form: true,
            },
          });
          setName("");
          setEmail("");
          setTermAndCondition(false);
          setForm(false);
        } else {
          toast.error("Error subscribing to newsletter!");
        }
      } catch {
        toast.error("Error subscribing to newsletter!");
      } finally {
        formRef?.current?.reset();
        setFormLoader(false);
      }
    }
  };

  const isValidEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <footer>
      <img src={assets.images.fgroup} alt="" className="foot__image" />
      <div className="container">
        {form ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.7 } }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
          >
            <div className="footerf">
              <BgCard>
                <div className="wrapperYelloe" style={{ overflow: "hidden" }}>
                  <div className="yellowFade pos"></div>
                </div>

                <div className="closeBtn" onClick={() => setForm(false)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.8174 2.56067L17.8951 0.706055L10.2741 8.05876L2.65302 0.706055L0.730713 2.56067L8.35176 9.91337L0.730713 17.2661L2.65302 19.1207L10.2741 11.768L17.8951 19.1207L19.8174 17.2661L12.1964 9.91337L19.8174 2.56067Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div className="formWrapper">
                  <div className="heading test formtex">
                    I would like to know about the{" "}
                    <span className="yellow">Celio launches</span>
                  </div>
                  <div className="formBox">
                    <form action="" ref={formRef} onSubmit={handleSubmit}>
                      <div className="inputBox">
                        <input
                          type="text"
                          placeholder="Name"
                          name="Name"
                          id=""
                          className="input"

                          onChange={(event) => setName(event.target.value)}
                        />
                        <small>{nameError}</small>
                      </div>
                      <div className="inputBox">
                        <input
                          type="text"
                          placeholder="Email"
                          name="Email"
                          id=""
                          className=""
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                        <small>{emailError}</small>
                      </div>
                      <div className="inputBox">
                        <div className="checkbox">
                          <input
                            type="checkbox"
                            name="checkbox"
                            className="checkbocstyle"
                            id=""
                            onChange={(e) =>
                              setTermAndCondition(e.target.checked)
                            }
                          />
                          <label htmlFor="" className="testf">
                            By clicking here, I agree to the terms and
                            conditions as set out by the user agreement.
                          </label>
                        </div>
                        <small>{termError}</small>
                      </div>
                      <div className="formBtnBox">
                        {formLoader ? (
                          <button
                            style={{ background: "transparent", border: "0px" }}
                          >
                            <Button text={"Loading..."}></Button>
                          </button>
                        ) : (
                          <button
                            onClick={() => "fromSubmit()"}
                            style={{ background: "transparent", border: "0px" }}
                          >
                            <Button text={"Submit"}></Button>
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </BgCard>
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.8 } }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
          >
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="footlogo">
                  <img src={assets.logos.celioT} alt="" />
                </div>
                <div className="text new">
                  Be the first to know about exclusive offers, deals &
                  newsletters. Delivered directly to your inbox!
                </div>
                <div onClick={() => setForm(true)} className="fBtn">
                  <Button
                    text="Share database"
                    // style={{ width: "900px" }}
                  ></Button>
                </div>
                <div className="right ffLogo mobile">
                  <a href="https://layer-e.com/" target="_blank">
                    <img src={assets.logos.layerE} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        )}
        <hr />
        <div className="footerBottom">
          <div className="left">
            <Link to={"/terms-and-conditions"}>Terms and Conditions</Link>
          </div>
          <div className="ffLogos pc">
            <a href="https://layer-e.com/" target="_blank">
              <img src={assets.logos.layerE} alt="" />
            </a>
          </div>
          <div className="right ">
            <div className="">Celio.collect 2023</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
