import React, { useState } from "react";
import styled from "styled-components";

import "./FAQ.css";

import assets from "../../assets/images";

const FAQ = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "What is Celio-Emiway collaboration all about?",
      answer:
        "Celio-Emiway collaboration is our way of celebrating Emiway’s journey in the industry and how he embodies the “Do it Your Way” campaign. This collaboration gives the fan access to exclusive merchandise, a metaverse where fans can Virtually meet Emiway from across the globe, and also exclusive passes to Emiway’s own          ",

      open: false,
    },
    {
      question: "What is Celioverse?",
      answer:
        "Celioverse is a virtual space where people can come together, meet Emiway and also buy exclusive merchandise all in one place! Think of it like a mall, but in the metaverse!        ",

      open: false,
    },
    {
      question: "What are the benefits of visiting Celioverse?",
      answer:
        "Fans who visit the Celioverse not only get to meet Emiway, but also get to be a part of many exciting events that happen in the space like, listen-together parties, song launches and much more. Apart from this users also get a chance to get a look at all of the Emiway’s exclusive merchandise, NFTs and much more.        ",

      open: false,
    },
    {
      question: "What is an NFT and how does it work?",
      answer:
        "An NFT is a digital asset that represents ownership or proof of authenticity of a unique item or piece of content. It's stored on a blockchain, which is a secure and transparent digital ledger. Unlike other cryptocurrencies, NFTs are unique and can't be exchanged for each other.         ",

      open: false,
    },
    // {
    //   question: "What kind of collectibles can I mint on the website?",
    //   answer:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non, lacinia ultrices leo. Morbi convallis gravida dapibus. Vestibulum tempor massa in venenatis semper. Nullam posuere purus eu semper maximus.",

    //   open: false,
    // },
    {
      question: "Can the collectible be customised?",
      answer: "Each collectible is unique and cannot be customized    ",

      open: false,
    },
    {
      question: "How do I purchase an NFT or redeem my discounts?",
      list: [
        "Select the NFT you want to mint.",
        "Click on claim the NFT button",
        "Give it a few seconds and VOILA! You have minted the NFT.",
        "To redeem the discount you can simply click on the claim, copy the discount code & use the code on Celio India website (do it your way collection only) to avail the discount.",
      ],

      open: false,
    },
    {
      question: "What happens when I open the chest on the website?",
      answer:
        "Like all chests this one shall also shower you with treasures like",
      list: [
        "Discounts",
        "Access to exclusive merchandise",
        "Limited Edition NFTs",
      ],

      open: false,
    },
    {
      question: "What is a Wallet and how do I get one?",
      list: [
        "A wallet is where all your collectibles will be stored. ",
        "You can simply get a wallet by clicking on the connect button on the home page and following the instructions.",
      ],

      open: false,
    },
    {
      question:
        "How can I access the Celio-Emiway collaboration in the metaverse?",
      answer:
        "Once you have created and connected your wallet you can click on the Celioverse.",

      open: false,
    },
    // {
    //   question: "What is the difference between an NFT and a traditional collectible?",
    //   answer:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non, lacinia ultrices leo. Morbi convallis gravida dapibus. Vestibulum tempor massa in venenatis semper. Nullam posuere purus eu semper maximus.",

    //   open: false,
    // },
    // {
    //   question: "How secure is my data and payment information on the website?",
    //   answer:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non, lacinia ultrices leo. Morbi convallis gravida dapibus. Vestibulum tempor massa in venenatis semper. Nullam posuere purus eu semper maximus.",

    //   open: false,
    // },
    // {
    //   question: "Can I sell or trade my NFTs on other platforms?",
    //   answer:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non, lacinia ultrices leo. Morbi convallis gravida dapibus. Vestibulum tempor massa in venenatis semper. Nullam posuere purus eu semper maximus.",

    //   open: false,
    // },{
    //   question: "How often will new Celio-Emiway collaboration drops occur?",
    //   answer:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non, lacinia ultrices leo. Morbi convallis gravida dapibus. Vestibulum tempor massa in venenatis semper. Nullam posuere purus eu semper maximus.",

    //   open: false,
    // },{
    //   question: "Will there be any exclusive merchandise or products available through the collaboration?",
    //   answer:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non, lacinia ultrices leo. Morbi convallis gravida dapibus. Vestibulum tempor massa in venenatis semper. Nullam posuere purus eu semper maximus.",

    //   open: false,
    // },{
    //   question: "How can I learn more about Celio-Emiway collaboration and updates?",
    //   answer:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non, lacinia ultrices leo. Morbi convallis gravida dapibus. Vestibulum tempor massa in venenatis semper. Nullam posuere purus eu semper maximus.",

    //   open: false,
    // },{
    //   question: "How do I contact customer support for any questions or concerns?",
    //   answer:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor sem, rutrum eu auctor non, lacinia ultrices leo. Morbi convallis gravida dapibus. Vestibulum tempor massa in venenatis semper. Nullam posuere purus eu semper maximus.",

    //   open: false,
    // },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <FaqWrapper>
      <img src={assets.images.cube} alt="" className="rigtimgd vert-move  " />
      <img src={assets.images.wballh} alt="" className="leftimgd vert-move " />

      <div className="container" id="support">
        <div className="faqHeader">
          <div className="heading">
            Frequently Asked <span className="yellow">Questions</span>
          </div>
        </div>
        <div className="faqBox accordion">
          <div className="faqs">
            {faqs.map((faq, index) => (
              <FAQCard
                faq={faq}
                index={index}
                key={index}
                toggleFAQ={toggleFAQ}
              />
            ))}
          </div>
        </div>
      </div>
    </FaqWrapper>
  );
};

const FaqWrapper = styled.div`
  /* background-color: black; */
  margin-bottom: 50px;
  position: relative;
  z-index: 10;
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .rigtimgd {
    position: absolute;
    left: -90px;
    bottom: 0;
    width: 250px;
  }
  .leftimgd {
    position: absolute;
    top: -190px;
    right: -450px;
    width: 850px;
  }

  .faqHeader {
    font-family: "CabinetGrotesk";
    font-weight: 900;
    font-size: 50px;
    line-height: 70px;
    text-align: center;
    margin-bottom: 55px;
  }
  .faqBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    z-index: 11;
  }
  @media only screen and (max-width: 768px) {
    .faqHeader {
      margin-bottom: 30px;
    }
    .leftimgd {
      display: none;
    }
    .rigtimgd {
      display: none;
    }
    .leftimgd {
      display: none;
    }
    min-height: auto;

    padding-bottom: 0px;

    .container {
      max-width: 100%;
      position: relative;
      z-index: 9;
      /* padding: 100px 0; */
      /* margin: 0 auto; */
    }
  }
`;

export default FAQ;

const FAQCard = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question ">
        <div className="qus">
          <svg
            className="svg"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.91667 17.1042C1.45833 17.1042 1.06583 16.9411 0.739168 16.615C0.412501 16.2889 0.249446 15.8964 0.250001 15.4375V3.77083C0.250001 3.3125 0.413335 2.92 0.740001 2.59333C1.06667 2.26667 1.45889 2.10361 1.91667 2.10417H9.35417L7.6875 3.77083H1.91667V15.4375H13.5833V9.64583L15.25 7.97917V15.4375C15.25 15.8958 15.0867 16.2883 14.76 16.615C14.4333 16.9417 14.0411 17.1047 13.5833 17.1042H1.91667ZM11.2292 2.58333L12.4167 3.75L6.91667 9.25V10.4375H8.08333L13.6042 4.91667L14.7917 6.08333L8.79167 12.1042H5.25V8.5625L11.2292 2.58333ZM14.7917 6.08333L11.2292 2.58333L13.3125 0.5C13.6458 0.166667 14.0453 0 14.5108 0C14.9764 0 15.3686 0.166667 15.6875 0.5L16.8542 1.6875C17.1736 2.00694 17.3333 2.39583 17.3333 2.85417C17.3333 3.3125 17.1736 3.70139 16.8542 4.02083L14.7917 6.08333Z"
              fill="white"
            />
          </svg>
          <div className="">{faq.question}</div>
        </div>
        <div className=" darrow">
          {faq.open ? (
            <img src={assets.logos.up} alt="" />
          ) : (
            <img src={assets.logos.down} alt="" />
          )}
        </div>
      </div>
      <div className="faq-answer">
        {faq?.answer ? <div className="dd">{faq?.answer}</div> : null}
        {faq?.list ? (
          <ul className="ddd">
            {faq?.list.map((s) => (
              <li>{s}</li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};
