import React from "react";
import styled, { keyframes } from "styled-components";

const Marquee = () => {
  return (
    <MarqeeWrapper>
      <MarqueeBody>
        <MarqueeGroup speed={20}>
          <div className="marqueeItem">
            <div>Celio</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
            <div>Emiway Bantai</div>
          </div>
        </MarqueeGroup>
        <MarqueeGroup speed={20}>
          <div className="marqueeItem">
            <div>Celio</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
            <div>Emiway Bantai</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
          </div>
        </MarqueeGroup>
        <MarqueeGroup speed={20}>
          <div className="marqueeItem">
            <div>Celio</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
            <div>Emiway Bantai</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
          </div>
        </MarqueeGroup>
        <MarqueeGroup speed={20}>
          <div className="marqueeItem">
            <div>Celio</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
            <div>Emiway Bantai</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
          </div>
        </MarqueeGroup>
        <MarqueeGroup speed={20}>
          <div className="marqueeItem">
            <div>Celio</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
            <div>Emiway Bantai</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
          </div>
        </MarqueeGroup>
        <MarqueeGroup speed={20}>
          <div className="marqueeItem">
            <div>Celio</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
            <div>Emiway Bantai</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
          </div>
        </MarqueeGroup>
        <MarqueeGroup speed={20}>
          <div className="marqueeItem">
            <div>Celio</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
            <div>Emiway Bantai</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
          </div>
        </MarqueeGroup>
        <MarqueeGroup speed={20}>
          <div className="marqueeItem">
            <div>Celio</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
            <div>Emiway Bantai</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
          </div>
        </MarqueeGroup>
        <MarqueeGroup speed={20}>
          <div className="marqueeItem">
            <div>Celio</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
            <div>Emiway Bantai</div>
            <div>
              <svg
              className="svg"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1538 0V9.72L2.23077 6.48L0 11.88L10.0385 15.12L3.34615 23.76L8.92308 27L14.5 18.36L21.1923 27L25.6538 23.76L18.9615 15.12L29 11.88L26.7692 6.48L16.7308 9.72V0L11.1538 0Z"
                  fill="#C73A33"
                />
              </svg>
            </div>
          </div>
        </MarqueeGroup>
      </MarqueeBody>
    </MarqeeWrapper>
  );
};

export default Marquee;
const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;
const MarqueeBody = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  user-select: none;
  width: 100%;
  /* max-width: 1200px; */
  position: relative;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;
const MarqueeGroup = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-around;
  /* min-width: 100%; */
  animation: ${scrollX} 20s linear infinite;
  gap: 1.5rem;
  padding: 0 1rem;
`;
const MarqeeWrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .marqueeItem {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    font-family: "Trap";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    .svg {
    width: 29px;
    height: 27px;
  }
  }
 
@media screen and (max-width: 768px) {
  .marqueeItem {
    display: flex;
    gap: 11px;
    justify-content: center;
    align-items: center;
    font-family: "Trap";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    .svg {
    width: 10px;
    height: 9px;
  }
  }

}
`;
