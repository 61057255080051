import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import assets from "../../assets/images";
import Benefits from "../../Components/Benefits/Benefits";
import BgCard from "../../Components/BgCard/BgCard";
import Biome from "../../Components/Biome/Biome";
import DotBox from "../../Components/DotBox/DotBox";
import FAQ from "../../Components/FAQ/FAQ";
import MintingPopup from "../../Components/PopUp/MintingPopup";
import Button from "../../Components/Utils/Button/Button";
import GlobalContext from "../../Contexts/GlobalContext";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";

// import { ADDRESS } from "../../assets/ABI/ABI";
import axios from "axios";
const ClaimPage = () => {
  const { address } = useAccount();
  const navigate = useNavigate();

  const { isMinitng, minted, isConnected, setisMinitng, BE , setPopUp} =
    useContext(GlobalContext);
  // const siteCode = process.env.REACT_APP_RECAPTCHA_SITE_CODE;

  const [isVerified, setIsVerified] = useState(false);
  const handleVerify = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };

  // const [message, setMessage] = useState({});
  var walletT = localStorage.getItem("wagmi.wallet");
  console.log(walletT.replace( /"/g, "" ))


  const claimCrate = async () => {

    try {
      setisMinitng(true);

      axios
        .post(`${BE}/create`, {
          wallet: address,
          walletType: walletT.replace( /"/g, "" )
        })
        .then((res) => {
          mint();
        })
        .catch((err) => {
          console.log(err);
          mint();
          if (err.response.status === 401) {
            toast.error("Bot Attempt detected !");
          }
        });
      function mint() {
        axios
          .post(`${BE}/claim`, {
            wallet: address,
            fingerprint: localStorage.getItem("secret"),
            walletType: walletT.replace( /"/g, "" )
          })
          .then((res) => {
            setisMinitng(false);
            toast.success("Minting successfully completed");
            navigate("/claim/success");
          })
          .catch((err) => {
            console.log(err);
            setisMinitng(false);

            if (err.response.status === 401) {
              toast.error("Bot Attempt detected !");
            } else if (err.response.status === 409) {
              toast.error("You are Already minted");
            } else if (
              err.response.data.message ===
                "Max mint limit reached\n0x08c379a00000000000000000…4206c696d6974207265616368656400000000000000000000" ||
              err.response.data.message ===
                "execution reverted: Max mint limit reached\n0x08c379a0000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000000164d6178206d696e74206c696d6974207265616368656400000000000000000000"
            ) {
              toast.error("Max mint limit reached");
            } else {
              toast.error("Error during minting process. Please try again");
            }
          });
      }
    } catch (error) {
      setisMinitng(false);
      toast.error("Failed to initialize ");

      console.log(error);
    }
  };

  useEffect(() => {
    setPopUp({ status: false });

    if (minted || !isConnected) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isConnected, minted, isVerified, setIsVerified]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, height: "auto", transition: { duration: 0.8 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <ClaimWrapper>
        {isMinitng ? <MintingPopup /> : null}
        <div className="wrapper">
          <div className="container">
            <div className="backBtn mobile" onClick={() => navigate("/")}>
              <img src={assets.logos.arrow} alt="" />
            </div>
            <div className="positions">
              <div className="yellowFade addPostion"></div>
            </div>
            <div className="">
              <BgCard>
                <div className="backBtn pc" onClick={() => navigate("/")}>
                  <img src={assets.logos.arrow} alt="" />
                </div>
                <div className="subhead">
                  Exclusive Emiway Bantai Collectible
                </div>
                <div className="CnftCard">
                  <img src={assets.images.nft} alt="" />
                </div>
                <div className="btnSEc">
                  {!isVerified ? (
                    <div
                      onClick={() => {
                        claimCrate();
                      }}
                    >
                      <Button text={"Claim collectible"}></Button>
                    </div>
                  ) : (
                    <div className="Captcha">
                      <ReCAPTCHA
                        sitekey={"6LcnrJgmAAAAANL9cWOGcMK-J5RmKCSSD39g7KCb"}
                        onChange={handleVerify}
                      />
                    </div>
                  )}
                </div>
              </BgCard>
            </div>
          </div>

          <div>
            <div className="benif">
              <Benefits />
            </div>
          </div>

          <section>
            <Biome />
          </section>

          <section>
            <FAQ />
          </section>

          <DotBox />
        </div>
      </ClaimWrapper>
    </motion.div>
  );
};

const ClaimWrapper = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  .backBtn {
    position: absolute;
    top: 50px;
    left: 40x;
    cursor: pointer;
  }
  .Captcha {
    width: max-content;
    margin: 0 auto;
  }
  .wrapper {
    width: 100%;
    overflow: hidden;
  }
  .positions {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addPostion {
  }
  .benif {
    margin-top: 30px;
  }
  .dd {
    width: 100%;
  }
  .CnftCard {
    /* padding: 40px 0; */
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      margin: 0 auto !important;
    }
  }
  .btnSEc {
    width: 100%;
    .button {
      margin: 0 auto;
    }
  }
  .benifitsec {
    /* width: 80vw; */
    /* background-color: var(--primary-color); */
  }

  @media screen and (max-width: 768px) {
    .recaptcha-container {
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
    .Captcha {
      width: 300px;
      margin: 0 auto;
    }
    .addPostion {
      position: absolute;
      left: 24%;
      top: 34%;
      width: 215px;
      height: 215px;
      background: #ebc43e;
      filter: blur(178.9px);
    }
    .wrapper {
      margin-top: 40px;
      width: 100%;
    }
    .backBtn {
      position: absolute;
      top: 110px;
      left: 50x;
      cursor: pointer;
      img {
        width: 20px;
      }
    }
    .CnftCard {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0px 0;

      img {
        margin: 20px auto !important;
        width: 271px;
      }
    }
  }
`;

export default ClaimPage;
