import celioLogo from "./logos/celio.svg";
import starDot from "./logos/starDot.svg";
import celioT from "./logos/celioT.svg";
import wplogo from "./logos/wplogo.svg";
import insta from "./logos/insta.svg";
import tweeter from "./logos/tweeter.svg";
import tweeter2 from "./logos/t2.svg";
import whatsapp2 from "./logos/whatsapp2.svg";
import inta2 from "./logos/i2.svg";
import arrow from "./logos/arrow.svg";
import down from "./logos/d.svg";
import up from "./logos/up.svg";
import layerE from "./logos/layer-e.svg";

import mute from "./logos/mute.png";
import unmute from "./logos/unmute.png";




// images
import cube from "./image/cube.png";
import wballh from "./image/wballh.png";
import NFTCARD from "./image/NFTCARD.gif";



import nft from "./image/nft.gif";
import biome from "./image/bioms.png";
import box from "./image/box.png";
import pipe from "./image/pip.png";
import ball from "./image/ball.png";
import wball from "./image/wball.png";
import bedage from "./image/bedage.png";
import circle from "./image/circle.png";
import daiment from "./image/daiment.png";
import sharp from "./image/sharp.png";
import wsharp from "./image/wsharp.png";
import wcircle from "./image/wcircle.png";
import whatsapp from "./image/wup.png";
import shape from "./image/shape.png";
import moon from "./image/moon.webp";
import cd from "./image/cd.png";
import cds from "./image/cds.png";

import box2 from "./image/box2.png";
import dots from "./image/dots.png";
import wdaibent from "./image/wdaibent.png";
import twitter from "./image/twitter.png";
import instagram from "./image/instagram.png";
import whatsappt from "./image/whatsapp.png";

import rolllarge from "./image/rolllarge.png";
import tshirt from "./image/tshirt.png";
import fgroup from "./image/fgroup.png";
import dotH from "./image/dotH.png";
import copy from "./image/copy.png";
import done from "./image/done.png";

// import copy from "./image/copy.png"
import s1 from "./image/merch/s1.png";
import s2 from "./image/merch/s2.png";
import s3 from "./image/merch/s3.png";
import s4 from "./image/merch/s4.png";
import s5 from "./image/merch/s5.png";
import s6 from "./image/merch/s6.png";
import s7 from "./image/merch/s7.png";
import s8 from "./image/merch/s8.png";
import s9 from "./image/merch/s9.png";
import s10 from "./image/merch/s10.png";
import s11 from "./image/merch/s11.png";
import s12 from "./image/merch/s12.png";




const merch ={
    s1,
  s2,
  s3,s4,s5,s6,s7,s8,s9,s10,s11,s12

}

const logos = {
  down,
  up,
  tweeter2,
  whatsapp2,
  inta2,
  arrow,
  starDot,
  celioLogo,
  celioT,
  tweeter,
  wplogo,
  insta,
  layerE,
  unmute,
  mute
};

const images = {
  NFTCARD,
  
  whatsappt,
  instagram,
  twitter,
  wballh,
  wdaibent,
  cds,
  dots,
  box2,
  done,
  copy,
  fgroup,
  dotH,
  cd,
  shape,
  rolllarge,
  moon,
  tshirt,
 
  nft,
  biome,
  box,
  pipe,
  circle,
  wball,
  wcircle,
  whatsapp,
  wsharp,
  bedage,
  daiment,
  sharp,
  ball,
  cube,
};

const assets = {
  logos,
  images,
 merch

};
export default assets;
