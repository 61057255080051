import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import GlobalContext from "../../Contexts/GlobalContext";
import Button from "../Utils/Button/Button";
import boxVedios from "../../assets/videos/CRATE.webm";
import assets from "../../assets/images";


const DropBox = () => {
  const { minted, isclaimed } = useContext(GlobalContext);

  const navigate = useNavigate();


  // <video autoPlay playsInline loop muted src={boxVedios}></video>

  return (
    <DropWrapper>
      <div className="container">
        <div className="lefts">
          <div className="heading width">
            Get <span className="yellow">stunning</span> Emiway Bantai NFTs and
            Merch
          </div>
          <div className="btednBox pc">
            {!minted ? (
              <div onClick={() => navigate("/claim")}>
                <Button text={"Claim Collectible"} />
              </div>
            ) :  !isclaimed  ? (
              <div onClick={() => navigate("/claim-coupon")}>
                <Button text={"Claim Coupon"} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="right">
          <div className="fadebox">
            <div className="fade"></div>
          </div>
          <div className="dropImg">
            <div className={"homebox"}>
              <img src={assets.images.box} alt="" />
            </div>
          </div>
        </div>
        <div className="btednBox mobile">
          {minted ? (
            <div onClick={() => navigate("/claim-coupon")}>
              <Button text={"Claim Coupon"} />
            </div>
          ) : (
            <div onClick={() => navigate("/claim")}>
              <Button text={"Claim Collectible"} />
            </div>
          )}
        </div>
      </div>
    </DropWrapper>
  );
};

const DropWrapper = styled.div`
  width: 100%;
  margin-top: 80px;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .width {
    width: 400px;
  }

  .right {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    .fadebox {
      position: absolute;
      right: -150px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fade {
      width: 206px;
      height: 207px;

      background: #ebc43e;
      filter: blur(130px);
    }

    .dropImg {
      position: relative;

      z-index: 4;
      width: 600px;
      img {
        margin-top: -100px;
        width: 100%;
      }
      video {
        margin-top: -100px;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    .right {
      .dropImg {
        position: relative;
        z-index: 4;
        width: 100%;
        img {
          margin-top: -50px;

          width: 400px;
        }
        video {
          margin-top: -50px;
          width: 100%;
        }
      }
    }
    .width {
      width: 330px;
    }
    @media screen and (max-width: 768px) {
      .width {
        width: auto;
        text-align: center;
      }
      margin-top: 40px;
      .container {
        flex-direction: column;
      }

      .right {
        position: relative;
        width: 100%;
        margin-top: 60px;
        .fadebox {
          position: absolute;
          right: auto;
          left: auto;

          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .fade {
          position: absolute;
          left: auto;
          right: auto;
          top: auto;

          background: #ebc43e;
          filter: blur(130px);
        }

        .dropImg {
          position: relative;
          z-index: 4;
          width: 100%;
          img {
            margin-top: -50px;

            width: 100%;
          }
          video {
            margin-top: -50px;
            width: 100%;
          }
        }
      }
    }
  }
`;

export default DropBox;
