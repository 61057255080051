// import { HashLoader } from "react-spinners";
import "./Loader.css";

const Loader = ({ loadingText = "Please Wait..." }) => {
  return (
    <div className="loaderl">
      <div className="loader__inner">
      <div className="loaders">
                <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_618_4553" fill="white">
                <path d="M80.0964 42C82.2523 42 84.0188 43.7516 83.8188 45.8982C83.0437 54.2139 79.8022 62.1429 74.4664 68.6445C68.3021 76.1558 59.724 81.2973 50.1938 83.193C40.6636 85.0887 30.7709 83.6212 22.2013 79.0407C13.6318 74.4602 6.91557 67.05 3.19706 58.0727C-0.521445 49.0954 -1.01217 39.1066 1.8085 29.808C4.62918 20.5095 10.5867 12.4767 18.666 7.07828C26.7454 1.67985 36.4466 -0.750183 46.1167 0.202241C54.487 1.02664 62.3858 4.34114 68.8139 9.67324C70.4732 11.0496 70.4627 13.5373 68.9382 15.0618C67.4138 16.5862 64.9544 16.5659 63.2663 15.225C58.1381 11.1518 51.9233 8.61906 45.3515 7.97179C37.4789 7.19641 29.581 9.17473 23.0035 13.5697C16.426 17.9646 11.5758 24.5043 9.27948 32.0743C6.98312 39.6444 7.38263 47.7765 10.4099 55.085C13.4372 62.3936 18.905 68.4263 25.8816 72.1554C32.8582 75.8845 40.912 77.0791 48.6707 75.5359C56.4294 73.9925 63.4129 69.8068 68.4314 63.6917C72.6207 58.587 75.2243 52.4016 75.9703 45.8953C76.2159 43.7534 77.9405 42 80.0964 42Z"/>
                </mask>
                <path d="M80.0964 42C82.2523 42 84.0188 43.7516 83.8188 45.8982C83.0437 54.2139 79.8022 62.1429 74.4664 68.6445C68.3021 76.1558 59.724 81.2973 50.1938 83.193C40.6636 85.0887 30.7709 83.6212 22.2013 79.0407C13.6318 74.4602 6.91557 67.05 3.19706 58.0727C-0.521445 49.0954 -1.01217 39.1066 1.8085 29.808C4.62918 20.5095 10.5867 12.4767 18.666 7.07828C26.7454 1.67985 36.4466 -0.750183 46.1167 0.202241C54.487 1.02664 62.3858 4.34114 68.8139 9.67324C70.4732 11.0496 70.4627 13.5373 68.9382 15.0618C67.4138 16.5862 64.9544 16.5659 63.2663 15.225C58.1381 11.1518 51.9233 8.61906 45.3515 7.97179C37.4789 7.19641 29.581 9.17473 23.0035 13.5697C16.426 17.9646 11.5758 24.5043 9.27948 32.0743C6.98312 39.6444 7.38263 47.7765 10.4099 55.085C13.4372 62.3936 18.905 68.4263 25.8816 72.1554C32.8582 75.8845 40.912 77.0791 48.6707 75.5359C56.4294 73.9925 63.4129 69.8068 68.4314 63.6917C72.6207 58.587 75.2243 52.4016 75.9703 45.8953C76.2159 43.7534 77.9405 42 80.0964 42Z" stroke="url(#paint0_linear_618_4553)" strokeWidth="58.5" mask="url(#path-1-inside-1_618_4553)"/>
                <defs>
                <linearGradient id="paint0_linear_618_4553" x1="77.3043" y1="27.087" x2="109.261" y2="49.3043" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CB4B35"/>
                <stop offset="1" stopColor="#CB4B35" stopOpacity="0.01"/>
                </linearGradient>
                </defs>
                </svg>
                </div>
        
        {/* <p>{loadingText}</p> */}
      </div>
    </div>
  );
};

export default Loader;
