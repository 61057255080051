import React from "react";
import styled from "styled-components";
import assets from "../../assets/images";

const BenefitsCard = ({ data }) => {
  return (
    <BenefitCardWrapper>
      <div className="cardWraper">
        <div className="redDotBox">
          <svg
            viewBox="0 0 47 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.0769 0V16.2L3.61538 10.8L0 19.8L16.2692 25.2L5.42308 39.6L14.4615 45L23.5 30.6L34.3462 45L41.5769 39.6L30.7308 25.2L47 19.8L43.3846 10.8L27.1154 16.2V0L18.0769 0Z"
              fill="#C73A33"
            />
          </svg>
        </div>
        <div className="cardHeading">{data?.title}</div>
        <div className="cardDescription">{data?.discreption}</div>
        <div className="imagebox">
          <img src={data.image} alt="" />
        </div>
      </div>
    </BenefitCardWrapper>
  );
};

const Benefits = () => {
  const benefitsData = [
    {
      title: "Discount Chest",
      discreption:
        " Unlocking a discount chest gets you exclusive discount coupons on the Emiway Merch. You can use these coupons directly on the Celio website and get special prices for you favourite piece of Emiway's latest collection.",
      image: assets.images.box2,
    },
    {
      title: "Join Our Community",
      discreption:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer  tortor sem, rutrum eu auctor non, lacinia ultrices leo.",
      image: assets.images.whatsapp,
    },
    {
      title: "Enter Contests",
      discreption:
        " Owning this special NFT also gives you access to exclusive contests that will not only get you more discounts but also free merch as well! More details will be shared soon",
      image: assets.images.bedage,
    },
  ];
  return (
    <BenefitWrapper>
      <img src={assets.images.dotH} className="doth pc" alt="" />

      <img src={assets.images.pipe} className="pipe" alt="" />
      <img
        src={assets.images.ball}
        className="ball pc vert-move tab-none"
        alt=""
      />
      <img
        src={assets.images.daiment}
        className="dai vert-move-reverse pc tab-none"
        alt=""
      />

      <img
        src={assets.images.wball}
        className="wball vert-move-reverse pc tab-none"
        alt=""
      />
      <img
        src={assets.images.wcircle}
        className="wc pc vert-move tab-none "
        alt=""
      />
      <img
        src={assets.images.wsharp}
        className="ws pc vert-move-reverse tab-none"
        alt=""
      />

      <img src={assets.images.rolllarge} className="roll " alt="" />

      <div className="wrapper">
        <div className="innerWrapper container">
          <div className="heading">
            Collector <span className="yellow">Benefits</span>
          </div>
          <div className="benifitCardWrapper">
            <div className="Bleft">
              <BenefitsCard data={benefitsData[0]} />

            </div>
            <div className="Bright">
              <BenefitsCard data={benefitsData[2]} />
            </div>
          </div>
        </div>
      </div>
    </BenefitWrapper>
  );
};
const BenefitCardWrapper = styled.div`
  background: #f8f8f8;
  box-shadow: 6.09886px 6.09886px 0px #000000;
  backdrop-filter: blur(38.1179px);
  max-width: 510px;
  min-height: 577px;
  color: #000000;
  position: relative;
  z-index: 4;

  .cardWraper {
    padding: 45px;
  }

  .redDotBox {
    margin-bottom: 20px;
    svg {
      width: 47px;
      height: 45px;
    }
  }
  .cardHeading {
    font-weight: 600;
    font-size: 44px;
    line-height: 100%;
    margin-bottom: 20px;
  }
  .cardDescription {
    font-family: "Trap";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    color: #646464;
  }

  .imagebox {
    /* width: 100%; */
    position: absolute;
    right: 0;
    bottom: 0px;

    img {
      width: 260px;
    }
  }
  @media screen and (max-width: 998px) {
    .tab-none {
      display: none;
    }
  }

  @media screen and (max-width: 760px) {
    max-width: auto;
    min-height: 420px;

    .redDotBox {
      margin-bottom: 15px;
      svg {
        width: 31px;
        height: 29px;
      }
    }
    .cardWraper {
      padding: 32px;
    }
    .cardHeading {
      font-weight: 600;
      font-size: 28px;
      line-height: 100%;
      margin-bottom: 20px;
    }
    .cardDescription {
      font-family: "Trap";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: #646464;
    }

    .imagebox {
      /* width: 100%; */
      position: absolute;
      right: 20px;
      bottom: 0px;

      img {
        width: 180px;
      }
    }
  }
`;
const BenefitWrapper = styled.div`
  width: 100%;
  .roll {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
  .doth {
    position: absolute;
    bottom: 0;
    left: 50px;
  }
  .pipe {
    position: absolute;
    right: 0;
    top: -180px;
  }
  .ball {
    position: absolute;
    right: 500px;
    top: 280px;
  }
  .dai {
    position: absolute;
    right: 200px;
    top: 380px;
  }
  .wball {
    position: absolute;
    right: 0px;
    bottom: 10px;
  }
  .ws {
    position: absolute;
    right: -160px;
    bottom: 280px;
  }
  .wc {
    position: absolute;
    right: 150px;
    bottom: 130px;
  }
  overflow: hidden;
  background-color: var(--primary-color);
  border-radius: 75px;
  position: relative;

  width: 100%;
  .wrapper {
    padding-bottom: 170px;
  }
  .innerWrapper {
    padding: 50px 90px;
  }

  .benifitCardWrapper {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
  }
  .Bleft {
    display: flex;
    flex-direction: column;
    gap: 319px;

  }
  .Bright {
    height: 1000px;

    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }
  @media screen and (max-width: 1350px) {
    .roll {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 550px;
    }
    .pipe {
      position: absolute;
      right: -50px;
      width: 550px;
      top: -60px;
    }
    .doth {
      position: absolute;
      bottom: 0;
      left: 50px;
      width: 600px;
    }

    .dai {
      position: absolute;
      right: 200px;
      top: 380px;
      width: 170px;
    }
    .wball {
      position: absolute;
      right: 0px;
      bottom: 10px;
    }
    .ball {
      position: absolute;
      right: 400px;
      top: 240px;
      width: 200px;
    }
  }

  @media screen and (max-width: 998px) {
    .tab-none {
      display: none;
    }
    .pipe {
      position: absolute;
      right: -90px;
      width: 400px;
      top: -60px;
    }
    .benifitCardWrapper {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 60px;
    }
    .Bleft {
      display: flex;
      flex-direction: column;
      gap: 60px;
    }
    .Bright {
      display: flex;
      flex-direction: column;
      gap: 60px;

      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 760px) {
    border-radius: 50px;
    margin-bottom: 40px;

    .wrapper {
      padding-bottom: 170px;
    }
    .innerWrapper {
      padding: 20px 20px;

      .heading {
        text-align: left;
        margin-top: 20px;
      }
    }
    .roll {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 270px;
    }
    .pipe {
      position: absolute;
      right: -60px;
      width: 220px;
      top: -70px;
    }

    .benifitCardWrapper {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 28px;
    }
    .Bleft {
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
    .Bright {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    height: auto;

    }
    .wrapper {
      padding-bottom: 120px;
    }
  }
`;
export default Benefits;
