import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import BgCard from "../../Components/BgCard/BgCard";
import Button from "../../Components/Utils/Button/Button";
import assets from "../../assets/images";
import boxVedios from "../../assets/videos/CRATE.webm";
import Marquee from "../../Components/Marquee/Marquee";
import ProgressSteps from "../../Components/ProgressSteps/ProgressSteps";
import { useNavigate, useParams } from "react-router-dom";
import {
  twitterSteps,
  instagramSteps,
  whatsappSteps,
  facebookSetp,
} from "./data";
import GlobalContext from "../../Contexts/GlobalContext";
import axios from "axios";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { setuid } from "process";
import DotBox from "../../Components/DotBox/DotBox";
import { saveAs } from 'file-saver'
import NFFLIST from "../../assets/NFT/nfts";


const Task = () => {
  const { address, BE, setPopUp, userData, isclaimed, findDiscont, user, indexedNFt } =
    useContext(GlobalContext);

  const { type } = useParams();
  const [taskList, setTask] = useState({});
  const [currentSetp, setCurrentStep] = useState(0);
  const [activeStep, setActiveStep] = useState(1);
  const [userId, setUserId] = useState("");
  const [isNext, setIsNext] = useState({ stage: 1, status: false });
  const [btnLoader, setBtnLoader] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [myNft, setMyneft] = useState();


  //   const [btn, setBtnAction] = useState({ step: 1, next: false });
  const navigate = useNavigate();

  function handleOnBlur() {
    if (userId && !PhoneInput.isValidPhoneNumber(userId)) {
      alert("Invalid phone number");
    }
  }
  useEffect(() => {
    setScreenWidth(window.innerWidth);

  }, [setScreenWidth]);
  useEffect(() => {

    if (isclaimed) {
      navigate("/claim-coupon");
    }
    if (type === "twitter") {

      setTask(twitterSteps);
      if (userData.isTwitterConnected && activeStep === 1) {
        setActiveStep(activeStep + 1);

      }
      if (localStorage.getItem("type") === "twitter") {
        setCurrentStep(1);
      }
    } else if (type === "instagram") {
      if (localStorage.getItem("type") === "instagram") {
        setCurrentStep(1);
      }
      setTask(instagramSteps);
    } else if (type === "facebook") {
 
      setTask(facebookSetp);

      if (localStorage.getItem("type") === "facebook") {
        setCurrentStep(1);
      }
    } else {
      navigate("/claim-coupon");
    }
  });

  const nextStep = () => {
    if (activeStep < taskList.steps.length) setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };
  const enterContestFun = () => {
    if (type === "twitter") {
      localStorage.setItem("type", type);
      setCurrentStep(1);
    }
    if (type === "instagram") {
      localStorage.setItem("type", type);
      setCurrentStep(1);
    }
    if (type === "facebook") {
      localStorage.setItem("type", type);
      setCurrentStep(1);
    }
  };

  const handleDownloadClick = () => {
  
    setMyneft(NFFLIST[userData.tokenId - 1]);
    const url = indexedNFt?.balances[0]?.tokenMetadata?.image;
    // const a = document.createElement("a");

    // if (typeof a.download === "undefined") {
    //   // Safari doesn't support the download attribute
    //   window.location.href = url;
    // } else {
    //   // Create a temporary object URL
    //   const blob = new Blob([url], { type: "image/gif" });
    //   const objectUrl = window.URL.createObjectURL(blob);

    //   a.href = objectUrl;
    //   a.download = "nft.gif";
    //   document.body.appendChild(a);
    //   a.click();

    //   // Clean up the temporary object URL
    //   window.URL.revokeObjectURL(objectUrl);

      // document.body.removeChild(a);
     saveAs(url, 'Celio_Collectible.mp4') 

      setIsNext({ stage: 1, status: true });
    // }
  };

  const handleTwitterClick = async () => {
    if (type === "twitter") {
      setIsNext({ stage: 2, status: true });
      // pic.twitter.com/c3KDZGHSDi
      const imageUrl = "https://celio.pages.dev/";
      const text = `🚀 Just minted my exclusive %20%23EmiwayMerch NFT! 🎉 Get yours now and join the contest for a chance to win amazing discounts on @Celio's @Emiway collection! 💥 %23CelioXEmiway `;
      const hashtags = "";
      const via = "celioindia,@emiwaytweets";
     //twitter.com/intent/tweet?text=gm%20polygon%20fam%21%20%F0%9F%92%9C%0D%0A%0D%0Agrab%20real-time%20analytics%2C%20juicy%20insights%2C%20top%20collections%2C%20and%20upcoming%20airdrops%20on%20https%3A%2F%2Fctt.
      // ec%2FW29rw%2B%20by%20%40LayerEhq%20%F0%9F%9A%80%0D%0A%0D%0Ago%20check%20it%20out%21%20&original_referer=https://clicktotweet.com&related=tweetdripapp
      const url = `https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtags}`;
      window.open(url, "_blank");
    } else if (type === "instagram") {
      setIsNext({ stage: 2, status: true });
      window.open("https://www.instagram.com/", "_blank");
    } else if (type === "facebook") {
      setIsNext({ stage: 2, status: true });
      window.open("https://www.facebook.com/", "_blank");
    }
  };

  const login = () => {
    // window.history.replaceState(null, "", "/results");
    if (type === "facebook") {
      window.location.replace(
        `${BE}/facebook/login?wallet=${address}&redirect_uri=${
          window.location.href + `?show_twitter_task=true`
        }`
      );
    } else if (type === "twitter") {
      window.location.replace(
        `${BE}/twitter/login?wallet=${address}&redirect_uri=${
          window.location.href + `?show_twitter_task=true`
        }`
      );
    }
  };

  const verifyTask = async () => {
    try {
      const res = await axios.get(`${BE}/twitter/tag/?wallet=${address}`);
      if (res.status === 200) {
        user();

        setPopUp({
          status: true,
          data: {
            condent:
              "Welcome to the community! Look out for more contests and surprises coming your way",
            task: true,
            buttonText: "Back",
            action: "/claim-coupon",
          },
        });
        findDiscont();
      } else {
        toast.error("Task ");
      }
    } catch {
      toast.error(
        "Post the collectible and tag @emiwaybantai and @celioindia  "
      );
    } finally {
      //   resetLoadingState(taskID === 1 ? "verifyingTwitter" : "verifyingDiscord");
    }
  };
  const verify = async () => {
    if (type === "instagram") {
      try {
        setBtnLoader(true);
        const res = await axios.get(
          `${BE}/task/verify/?wallet=${address}&task=${type}&user_id=${userId}`
        );
        if (res.status === 200) {
          setPopUp({
            status: true,
            data: {
              condent:
                "Welcome to the community! Look out for more contests and surprises coming your way",
              task: true,
              buttonText: "Back",
              action: "/claim-coupon",
            },
          });
          user();
          findDiscont();
        } else {
      toast.error("Failed to initialize ");


        }
      } catch {
        // toast.error("Failed to initialize ");

      } finally {
        setBtnLoader(false);
      }
    }
    if (type === "facebook") {
      try {
        setBtnLoader(true);
        const res = await axios.get(
          `${BE}/task/verify/?wallet=${address}&task=${type}&user_id=${userId}`
        );
        if (res.status === 200) {
          user();

          setPopUp({
            status: true,
            data: {
              condent:
                "Congratulations on completing the contest! Look out for more contests and surprises coming your way",
              task: true,
              buttonText: "Back",
              action: "/claim-coupon",
            },
          });
          findDiscont();
        } else {
      toast.error("Failed to initialize ");


        }
      } catch {
        console.log("bedd");
      // toast.error("Failed to initialize ");

      } finally {
        setBtnLoader(false);
      }
    }
    if (type === "twitter") {
      try {
        setBtnLoader(true);
        const res = await axios.get(
          `${BE}/task/verify/?wallet=${address}&task=${type}&user_id=${userId}`
        );
        if (res.status === 200) {
          user();

          setPopUp({
            status: true,
            data: {
              condent:
                "Congratulations on completing the contest! Look out for more contests and surprises coming your way",
              task: true,
              buttonText: "Back",
              action: "/claim-coupon",
            },
          });
          findDiscont();
        } else {
      toast.error("Failed to initialize ");


        }
      } catch {
        // toast.error("Failed to initialize ");

      } finally {
        setBtnLoader(false);
      }
    }
    // try {

    //   const res = await axios.get(`${BE}/twitter/tag/?wallet=${address}`);
    //   if (res.status === 200) {
    //     console.log("ss");
    //   } else {
    //     console.log("seees");
    //   }
    // } catch {
    //   console.log("bedd");
    // }
    // }
  };

  return (
    <TaskWrapper>
      <div className="climeHead ">
        <div className="headWrapper container">
          <div className="left">
            <div className="heading">
              {taskList?.Heading} <span className="yellow">Contest </span>
            </div>
          </div>
          <div className="right"></div>
        </div>
      </div>

      <div className="container" style={{ marginBottom: "80px" }}>
        {taskList?.length ? (
          <div className="prograsssStapWrapper mobile">
            <ProgressSteps
              steps={taskList?.steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </div>
        ) : null}
        <div className="">
          <BgCard>
            <div className="totalwrapper">
              <div className="leftSide">
                <div className="yellowFade postioncard"></div>

                <div className="dotBoxSmall pc ">
                  <img src={assets.images.dots} className="pc" alt="" />
                </div>
                <div className="dropbox">
                  <div className="dotbox mobile">
                    <DotBox />
                  </div>
                  <div className={"homebox"}>
                    {type === "twitter" ? (
                      <img src={assets.images.twitter} alt="" />
                    ) : type === "instagram" ? (
                      <img src={assets.images.instagram} alt="" />
                    ) : (
                      <img src={assets.images.whatsappt} alt="" />
                    )}
                  </div>
                </div>
              </div>
              {currentSetp === 0 ? (
                <div className="rightSide">
                  <div className="prograsssStapWrapper"></div>
                  <div className="maintTextBox">
                    <div className="textsBoxs">{taskList?.initaial?.title}</div>
                  </div>
                  <div className="smalltest">
                    {taskList?.initaial?.discription}
                  </div>
                  <div className="buttonBox">
                    <Button
                      text={taskList?.initaial?.button.buttonText}
                      click={enterContestFun}
                    />
                  </div>
                </div>
              ) : (
                <div className="rightSide">
                  {taskList?.length ? (
                    <div className="prograsssStapWrapper mobile">
                      <ProgressSteps
                        steps={taskList?.steps}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                      />
                    </div>
                  ) : null}

                  {taskList.steps.map((item) => {
                    if (item.step === activeStep) {
                      return (
                        <div style={{ width: "100%" }}>
                          <div className="maintTextBox">
                            <div className="textsBoxs">{item.title}</div>
                          </div>
                          <div className="smalltest">{item.discription}</div>
                          <div className="buttonBox">
                            {item?.button?.map((btn) => {
                              if (btn.action === "auth") {
                                return (
                                  <div
                                    onClick={() => {
                                      login();
                                    }}
                                  >
                                    <Button
                                      text={btn?.buttonText}
                                      disabled={"true"}
                                    />
                                  </div>
                                );
                              }
                              if (btn.action == "download") {
                                return (
                                  <div
                                    onClick={() => {
                                      handleDownloadClick();
                                    }}
                                  >
                                    <Button
                                      text={btn?.buttonText}
                                      disabled={"true"}
                                    />
                                  </div>
                                );
                              } else if (btn.action === "next") {
                                return isNext.status &&
                                  activeStep === isNext.stage ? (
                                  <div
                                    onClick={() => {
                                      nextStep();
                                    }}
                                  >
                                    <Button
                                      text={btn?.buttonText}
                                      disabled={"true"}
                                    />
                                  </div>
                                ) : (
                                  <div className="btnd">
                                    <button className="btndisable">Next</button>
                                  </div>
                                );
                              } else if (btn.action === "share") {
                                return (
                                  <div
                                    onClick={() => {
                                      handleTwitterClick();
                                    }}
                                  >
                                    <Button
                                      text={btn?.buttonText}
                                      disabled={"true"}
                                    />
                                  </div>
                                );
                              } else if (btn.action === "verify") {
                                return (
                                  <div
                                    onClick={() => {
                                      verifyTask();
                                    }}
                                  >
                                    <Button
                                      text={btn?.buttonText}
                                      disabled={"true"}
                                    />
                                  </div>
                                );
                              }
                            })}
                          </div>
                          {item.input ? (
                            <div className="inputfiled">
                             
                                <div className="input">
                                  <input
                                    onChange={(e) => setUserId(e.target.value)}
                                    type="text"
                                    name=""
                                    placeholder="Enter user name"
                                    id=""
                                  />
                                  <button
                                    className={"inta"}
                                    disabled={userId === "" ? true : false}
                                    onClick={() => {
                                      verify();
                                      setBtnLoader(true);
                                    }}
                                  >
                                    {btnLoader ? (
                                      <svg
                                        className="btnloaders"
                                        width="33"
                                        height="33"
                                        viewBox="0 0 33 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M30.7039 16.5C31.972 16.5 33.0164 17.533 32.8405 18.7888C32.4218 21.7783 31.1882 24.6114 29.2547 26.9675C26.833 29.9184 23.463 31.9382 19.719 32.683C15.975 33.4277 12.0886 32.8512 8.72195 31.0517C5.35534 29.2522 2.71683 26.3411 1.25599 22.8143C-0.204853 19.2875 -0.397637 15.3633 0.710484 11.7103C1.81861 8.05732 4.15907 4.90157 7.33309 2.78075C10.5071 0.659939 14.3183 -0.294715 18.1173 0.0794518C21.1505 0.378198 24.0261 1.5093 26.436 3.32707C27.4485 4.09071 27.4403 5.55965 26.5437 6.45634C25.647 7.35304 24.2023 7.33062 23.1504 6.6224C21.524 5.52742 19.6419 4.84409 17.6672 4.64959C14.9255 4.37956 12.175 5.06852 9.88441 6.59907C7.59378 8.12962 5.90471 10.4071 5.105 13.0434C4.30529 15.6797 4.44442 18.5117 5.49868 21.0569C6.55294 23.6021 8.45711 25.703 10.8867 27.0017C13.3164 28.3004 16.1211 28.7164 18.8231 28.1789C21.5251 27.6415 23.9571 26.1838 25.7048 24.0542C26.9636 22.5203 27.8113 20.7063 28.1871 18.782C28.4301 17.5374 29.4358 16.5 30.7039 16.5Z"
                                          fill="url(#paint0_linear_900_5960)"
                                        />
                                        <defs>
                                          <linearGradient
                                            id="paint0_linear_900_5960"
                                            x1="30"
                                            y1="2"
                                            x2="44"
                                            y2="23"
                                            gradientUnits="userSpaceOnUse"
                                          >
                                            <stop stop-color="white" />
                                            <stop
                                              offset="1"
                                              stop-color="white"
                                              stop-opacity="0"
                                            />
                                          </linearGradient>
                                        </defs>
                                      </svg>
                                    ) : (
                                      "Verify"
                                    )}
                                  </button>
                                </div>
                             
                            </div>
                          ) : null}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </BgCard>
        </div>
      </div>
      <Marquee />
    </TaskWrapper>
  );
};

const TaskWrapper = styled.div`
  margin-top: 60px;
  .rightSide {
    position: relative;
    z-index: 4;
  }

  .homebox {
    img {
      width: 300px;
    }
  }
  .textsBoxs {
    font-size: 44px;
    margin-bottom: 20px;
  }
  .smalltest {
    font-size: 24px;
  }
  .inputfiled {
    background: #5a5a5a;
    border-radius: 37px;
    padding: 0 30px;
    position: relative;
    z-index: 40;
    .PhoneInput {
      height: 100%;
    }

    .input {
      height: 72px;
      width: 100%;

      input {
        background: transparent;
        color: #fff;
        border-radius: 37px;
        border: none;
        height: 100%;
        width: inherit;

        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        :focus {
          border: none;
          outline: none;
        }
        :focus-visible {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
      button {
        cursor: pointer;
        position: absolute;
        right: 6px;
        bottom: 6px;
        border-radius: 37px;
        width: 170px;
        top: 6px;
        border: none;
        height: 60px;
        font-weight: 700;
        font-size: 24px;
        background-color: rgba(65, 147, 239, 1);
        color: #fff;
        :disabled {
          background-color: rgba(164, 204, 244, 1);
          cursor: auto;
        }
      }
      .inta {
        background-color: rgba(65, 147, 239, 1);

        :disabled {
          background-color: rgba(164, 204, 244, 1);
        }
      }
      .facebook {
        background-color: rgba(164, 204, 244, 1);

        :disabled {
          background-color: rgba(164, 204, 244, 1);
        }
      }
    }
  }
  .btnloaders {
    border-radius: 50%;
    width: 33px;
    height: 33px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .btnd {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .btndisable {
    background: #737373;
    box-shadow: inset 0px -4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    width: 420px;
    height: 80px;
    border: none;
    font-size: 24px;
    font-weight: 800;
    color: #bcbcbc;
    margin: 0 auto;
  }

  @media screen and (max-width: 1358px) {
    .btnd {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .btndisable {
      background: #737373;
      box-shadow: inset 0px -4px 9px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
      width: 360px;
      height: 72px;
      border: none;
      font-size: 22px;
      font-weight: 800;
      color: #bcbcbc;
      margin: 0 auto;
    }

    .inputfiled {
      background: #5a5a5a;

      border-radius: 37px;
      padding: 0 30px;
      position: relative;

      .input {
        height: 72px;
        width: 100%;

        input {
          border-radius: 37px;

          height: 100%;
          width: inherit;

          font-weight: 600;
          font-size: 24px;
          line-height: 130%;
        }
        button {
          right: 6px;
          bottom: 6px;
          border-radius: 37px;
          width: 170px;
          top: 6px;

          height: 60px;
          font-weight: 700;
          font-size: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .headWrapper {
      padding-left: 20px;
    }
    .dotBoxSmall {
      rotate: 90deg;
      img {
        width: 100%;
      }
    }
    .dotbox {
      margin: 30px 0;
      width: 100%;
    }
    .homebox {
      position: absolute;
      img {
        width: 200px;
        margin-bottom: 20px;
      }
    }
    .textsBoxs {
      font-weight: 800;
      font-size: 24px;
      line-height: 120%;
      text-align: left;
      margin: 0;
    }

    .smalltest {
      margin-top: 20px;
      font-weight: 500;
      font-size: 16px;
      text-align: left;
      line-height: 130%;
      margin-bottom: 50px;
    }

    .btnd {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }
    .btndisable {
      background: #737373;
      box-shadow: inset 0px -4px 9px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
      width: 240px;
      height: 48px;
      border: none;
      font-size: 16px;
      font-weight: 800;
      color: #bcbcbc;
      margin: 0 auto;
    }
    .inputfiled {
      background: #5a5a5a;

      border-radius: 37px;
      padding: 0 30px;
      position: relative;

      .input {
        height: 50px;
        width: 100%;

        input {
          border-radius: 37px;

          height: 100%;
          width: inherit;

          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
        }
        button {
          right: 3px;
          bottom: 3px;
          border-radius: 37px;
          width: 100px;
          top: 3px;

          height: 44px;
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
  }
`;

export default Task;
