import { Link } from "react-router-dom";
import "./Button.css";

const Button = ({
  text,
  click,
  link = "",
  href = "",
  imgbefore,
  imgafter,
  loader,
}) => {
  return (
    <div className="button__outer " onClick={click}>
      {link ? (
        <div className="outerLineBtn">
          <Link to={link} className="button ribbon-outset border">
            <div className="button__inner">
              {" "}
              <span>
                {" "}
                {imgbefore && (
                  <img src={imgbefore} alt="" className="beforetext" />
                )}{" "}
                {text}
              </span>{" "}
              {imgafter && <img src={imgafter} alt="" className="imgafter" />}
            </div>
          </Link>
        </div>
      ) : loader ? (
        <div className="button ribbon-outset border">
          <div className="button__inner">
            <div className="loading">
              <span className="loading__dot"></span>
              <span className="loading__dot"></span>
              <span className="loading__dot"></span>
            </div>
          </div>
        </div>
      ) : !href ? (
        // <div className="outerLineBtn">

        <div className="button ribbon-outset border">
          <div className="button__inner">
            {" "}
            <span>
              {" "}
              {imgbefore && (
                <img src={imgbefore} alt="" className="beforetext" />
              )}{" "}
              {text}
            </span>{" "}
            {imgafter && <img src={imgafter} alt="" className="imgafter" />}
          </div>
        </div>
      ) : (
        // </div>
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className="button ribbon-outset border"
        >
          <div className="button__inner">
            {" "}
            <span>
              {" "}
              {imgbefore && (
                <img src={imgbefore} alt="" className="beforetext" />
              )}
              {text}
            </span>{" "}
            {imgafter && <img src={imgafter} alt="" className="imgafter" />}
          </div>
        </a>
      )}
    </div>
  );
};

export default Button;
