import assets from "../images";

export const products = [
  {
    id: "01",
    productName: " Lime Printed Tank Top",
    productCompany: "Emiway Bantai x Celio",
    price: 999,
    url:'https://www.celio.in/emiway-bantai-x-celio-lime-printed-tank-top-various-sizes/14665687.html',

    productImage: assets.merch.s1,
  },
  {
    id: "01",
    productName: "Black Graphic Print Tshirt",
    productCompany: "Emiway Bantai x Celio",
    price: 1299,
    url:'https://www.celio.in/emiway-bantai-x-celio-black-graphic-print-tshirt-various-sizes/14665660.html',

    productImage: assets.merch.s2,
  },
  {
    id: "03",
    productName: "Black GraphicPrint Tshirt",
    productCompany: "Emiway Bantai x Celio",
    price: 1299,
    url:'https://www.celio.in/emiway-bantai-x-celio-black-graphic-print-tshirt-various-sizes/14665693.html',

    productImage: assets.merch.s3,
  },
  {
    id: "04",
    productName: " Black Abstract Print Short Sleeve Shirt",
    productCompany: "Emiway Bantai x Celio",
    price: 1999,
    url:'https://www.celio.in/emiway-bantai-x-celio-black-abstract-print-short-sleeve-shirt-various-sizes/14665673.html',

    productImage: assets.merch.s4,
  },
  {
    id: "05",
    productName: "Black Tye-Dye Short Sleeve Shirt",
    productCompany: "Emiway Bantai x Celio",
    price: 1999,
    url:'https://www.celio.in/emiway-bantai-x-celio-black-tye-dye-short-sleeve-shirt-various-sizes/14665680.html',

    productImage: assets.merch.s5,
  },
  {
    id: "06",
    productName: " Printed Shorts",
    productCompany: "Emiway Bantai x Celio",
    price: 1189,
    url:'https://www.celio.in/emiway-bantai-x-celio-printed-shorts-various-sizes/14665727.html',

    productImage: assets.merch.s6,
  },
  {
    id: "07",
    productName: "Abstract Print Short Sleeve Shirt",
    productCompany: "Emiway Bantai x Celio",
    price: 1999,
    url:'https://www.celio.in/emiway-bantai-x-celio-abstract-print-short-sleeve-shirt-various-sizes/14665666.html',

    productImage: assets.merch.s7,
  },
  {
    id: "08",
    productName: "KOTS White Printed Oversized Tshirt",
    productCompany: "Emiway Bantai x Celio",
    price: 1499,
    url:'https://www.celio.in/emiway-bantai-x-celio-kots-white-printed-oversized-tshirt-various-sizes/14665699.html',

    productImage: assets.merch.s8,
  },

  {
    id: "09",
    productName: "KOTS White Printed Oversized Tshirt ",
    productCompany: "Emiway Bantai x Celio",
    price: 1499,
    url:'https://www.celio.in/emiway-bantai-x-celio-kots-white-printed-oversized-tshirt-various-sizes/14665713.html',

    productImage: assets.merch.s9,
  },

  {
    id: "10",
    productName: " KOTS White Printed Oversized Tshirt ",
    productCompany: "Emiway Bantai x Celio",
    price: 1499,
    url:'https://www.celio.in/emiway-bantai-x-celio-kots-white-printed-oversized-tshirt-various-sizes/14665706.html',

    productImage: assets.merch.s10,
  },

  {
    id: "11",
    productName: "KOTS Black Printed Oversized  Tshirt",
    productCompany: "Emiway Bantai x Celio",
    price: 1699,
    url:'https://www.celio.in/emiway-bantai-x-celio-kots-black-printed-oversized-tshirt-various-sizes/14665720.html',

    productImage: assets.merch.s11,
  },
  {
    id: "12",
    productName: "Tye-Die Tshirt",
    productCompany: "Emiway Bantai x Celio",
    price: 1299,
    url:'https://www.celio.in/emiway-bantai-x-celio-tye-die-tshirt-various-sizes/14665654.html',

    productImage: assets.merch.s12,
  }
  // {
  //   id: "01",
  //   productName: "T-Shirt",
  //   productCompany: "Emiway Bantai x Celio",
  //   price: 25,

  //   productImage: assets.merch.s2,
  // },
  // {
  //   id: "03",
  //   productName: "T-Shirt",
  //   productCompany: "Emiway Bantai x Celio",
  //   price: 25,
  //   productImage:
  //   assets.merch.s4,

  // },
  // {
  //   id: "06",
  //   productName: "T-Shirt",
  //   productCompany: "Emiway Bantai x Celio",
  //   price: 25,
  //   productImage:
  //   assets.merch.s7,

  // },

  // {
  //   id: "09",
  //   productName: "T-Shirt",
  //   productCompany: "Emiway Bantai x Celio",
  //   price: 25,
  //   productImage:
  //   assets.merch.s10,

  // },

  // {
  //   id: "14",
  //   productName: "T-Shirt",
  //   productCompany: "Emiway Bantai x Celio",
  //   price: 25,
  //   productImage:
  //   assets.merch.s15,

  // },
  // {
  //   id: "15",
  //   productName: "T-Shirt",
  //   productCompany: "Emiway Bantai x Celio",
  //   price: 25,
  //   productImage:
  //   assets.merch.s16,

  // },
  // {
  //   id: "16",
  //   productName: "T-Shirt",
  //   productCompany: "Emiway Bantai x Celio",
  //   price: 25,
  //   productImage:
  //   assets.merch.s17,

  // },
  // {
  //   id: "17",
  //   productName: "T-Shirt",
  //   productCompany: "Emiway Bantai x Celio",
  //   price: 25,
  //   productImage:
  //   assets.merch.s18,

  // },
];
