import { Link } from "react-router-dom";
import "./Button.css";
import { Box } from '@0xsequence/design-system'

const ButtonConnect = ({
  children, ...props
}) => {
  return (

    <Box as="button"  {...props} className="button__outer">
      <div className="button ribbon-outset border">
        <div className="button__inner">
        {children}
        </div>
      </div>
    </Box >
  );
};

export default ButtonConnect;
