import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import assets from "../../assets/images";
import BgCard from "../../Components/BgCard/BgCard";
import DotBox from "../../Components/DotBox/DotBox";
import Tasks from "../../Components/Tasks/Tasks";
import Button from "../../Components/Utils/Button/Button";
import boxVedios from "../../assets/videos/CRATE.webm";

import GlobalContext from "../../Contexts/GlobalContext";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import axios from "axios";
import { toast } from "react-toastify";
const ClaimCoupon = () => {
  const { address, isConnected } = useAccount();

  const {
    isclaimed,
    setisClaimed,
    setPopUp,
    minted,
    vedioPaly,
    setVedioPlay,
    userData,
    user,
    BE,
    setUserData,
    // findDiscont,
    discoundScore,
    setDiscoundScore,
  } = useContext(GlobalContext);

  const [showBuyBtn, setShowBuyBtn] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  // const [discoundScore, setDiscoundScore] = useState(25);

  // eslint-disable-next-line

  const findDiscont = () => {
    const facebook = userData?.facebookTask;
    const twitter = userData?.twitterTask;
    const instagram = userData?.instagramTask;
    let totalDiscound = 25;

    if (facebook) {
      totalDiscound = totalDiscound + 25;
      setDiscoundScore(totalDiscound);
    }
    if (twitter) {
      totalDiscound = totalDiscound + 25;
      setDiscoundScore(totalDiscound);
    }
    if (instagram) {
      totalDiscound = totalDiscound + 25;
      setDiscoundScore(totalDiscound);
    }
  };

  const aa = () => {
    // window.location.replace(
    //   `https://celio.up.railway.app/facebook/login`

    // );
    const content = "This is my custom post content.";
    const url = `https://www.facebook.com/post?text=${encodeURIComponent(
      content
    )}`;
    window.open(url, "_blank");
  };
  const couponClaim = async () => {
    let couponId;
    if (discoundScore === 25) {
      couponId = 1;
    } else if (discoundScore === 50) {
      couponId = 2;
    } else if (discoundScore === 75) {
      couponId = 3;
    } else if (discoundScore === 100) {
      couponId = 4;
    }
    if (couponId > 0 && couponId <= 4) {
      axios
        .get(`${BE}/claim-coupon/${address}/${couponId}`)
        .then((res) => {
          toast.success("Minting successfully completed");
          setisClaimed(true);
          findDiscont();

          // navigate("/claim/success");
        })
        .catch((err) => {
          console.log(err);

          if (err.response.status === 401) {
            toast.success("Bot Attempt detected !");
          } else {
            if (
              err.response.data.message ===
              " Max mint limit reached\n0x08c379a00000000000000000…4206c696d6974207265616368656400000000000000000000"
            ) {
              toast.success("Max mint limit reached");
            }
          }
          if (err.response.status === 409) {
            toast.error("You are Already minted");
          }
        });
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
    const userdata = user();

    if (userdata) {
      setTimeout(() => {
        if (userData?.isMinted === false || !isConnected) {
          navigate("/");
        }
      }, 2000);
    }

    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }

    setPopUp({ status: false });
    setTimeout(() => {
      setVedioPlay(true);
    }, 6000);
    // return setDiscoundScore(25);
  }, [isCopied, discoundScore, setUserData, isConnected, isclaimed]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, height: "auto", transition: { duration: 0.8 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <CouponWrapper>
        <img src={assets.images.pipe} className="pip" alt="" />
        <img src={assets.images.rolllarge} className="roll pc" alt="" />
        <div className="section">
          <div className="yellowFade addPostion"></div>
          <div className="container">
            <BGCardWrappernew>
              <div className="dropWraper">
                <div
                  className={
                    vedioPaly
                      ? "video__wrapper dropBox "
                      : "video__wrapper dropBox dropBoxno"
                  }
                >
                  <video autoPlay playsInline muted src={boxVedios}></video>
                </div>
                <div className={vedioPaly ? "subhead " : "subhead opacity"}>
                  Whoohoo! <br />
                  You've unlocked discount on CelioxEmiwayBantai merch
                </div>

                <div className="dotwrapper">
                  <DotBox />
                </div>
              </div>

              {vedioPaly ? (
                <motion.div
                  className="news"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    height: "auto",
                    transition: { duration: 0.8 },
                  }}
                  exit={{ opacity: 0, transition: { duration: 0.5 } }}
                >
                  <div className="sliderBox">
                    <div className="sliderWrapper">
                      <div className="rela">
                        <div className="SliderProgras">
                          <div
                            className="track"
                            style={{ width: `${discoundScore}%` }}
                          >
                            <div className="ball">
                              <img src={assets.images.wball} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="textBox">
                          <div className="stop-point " data-value={"25"}>
                            <div
                              className={
                                discoundScore === 25
                                  ? "discound active"
                                  : "discound"
                              }
                            >
                              Rs 250 off
                            </div>
                            {discoundScore === 25 ? (
                              <div className="current">in Stock</div>
                            ) : null}
                          </div>
                          <div className="stop-point " data-value="50">
                            <div
                              className={
                                discoundScore === 50
                                  ? "discound active"
                                  : "discound"
                              }
                            >
                              Rs 300 off
                            </div>
                            {discoundScore === 50 ? (
                              <div className="current">in Stock</div>
                            ) : null}
                          </div>
                          <div className="stop-point" data-value="75">
                            <div
                              className={
                                discoundScore === 75
                                  ? "discound active"
                                  : "discound"
                              }
                            >
                              {" "}
                              Rs 400 off
                            </div>
                            {discoundScore === 75 ? (
                              <div className="current">in Stock</div>
                            ) : null}
                          </div>
                          <div className="stop-point" data-value="100">
                            <div
                              className={
                                discoundScore === 100
                                  ? "discound active"
                                  : "discound"
                              }
                            >
                              Rs 500 off
                            </div>
                            {discoundScore === 100 ? (
                              <div className="current">in Stock</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="textBoxs">
                    <div className="subhead updated">
                      Share your collectible and increase your discounts
                    </div>
                    <div className="texts">
                      Complete the tasks below and unlock more discounts to
                      purchase Emiway Bantai merchandise.
                    </div>
                  </div>

                  <div className="taskbox">
                    <Tasks />
                  </div>

                  <div className="fotterClimp">
                    {showBuyBtn ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          height: "auto",
                          transition: { duration: 0.8 },
                        }}
                        exit={{ opacity: 0, transition: { duration: 0.5 } }}
                      >
                        <div className="smallText pc">
                          Note: Coupon code copied! Now you can visit the store
                          & purchase exclusive Emiway Bantai Merch
                        </div>
                      </motion.div>
                    ) : null}
                    <div className="btns">
                      {isclaimed ? (
                        isCopied ? (
                          <div
                            onClick={() => {
                              navigator.clipboard.writeText(userData?.couponCode);
                              setShowBuyBtn(true);
                            }}
                          >
                            <Button
                              text={"Copied"}
                              // imgafter={assets.images.copy}
                            />
                          </div>
                        ) : (
                          <>
                            <div
                              onClick={() => {
                                navigator.clipboard.writeText(userData?.couponCode);
                                setIsCopied(true);

                                setShowBuyBtn(true);
                              }}
                            >
                              <Button
                                text={userData?.couponCode}
                                imgafter={assets.images.copy}
                              />
                            </div>
                          </>
                        )
                      ) : (
                        <div
                          onClick={() => {
                            couponClaim();
                          }}
                        >
                          <Button text={"Claim Coupon"} />
                        </div>
                      )}

                      {showBuyBtn ? (
                        <Button
                          text={"View Collections"}
                          href={"https://www.celio.in/do-it-your-way.list"}
                        />
                      ) : null}
                    </div>
                  </div>
                </motion.div>
              ) : null}
            </BGCardWrappernew>
          </div>
        </div>
      </CouponWrapper>
    </motion.div>
  );
};
const BGCardWrappernew = styled.div`
  overflow: hidden;
  padding: 50px 0;
  border: 1px solid #c4c4c4;
  backdrop-filter: blur(10px);
  border-radius: 24px;
  @media screen and (max-width: 768px) {
    border-radius: 12px;
    padding: 0px;

    border: none;
    backdrop-filter: blur(0px);
  }
`;
const CouponWrapper = styled.div`
  .news {
    padding: 0 50px;
  }
  .pip {
    position: absolute;
    right: -50px;
    top: -120px;
    width: 600px;
  }
  .roll {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 500px;
  }

  position: relative;
  margin-top: 60px;
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .subhead {
    transition: all ease 0.5s;
  }
  .opacity {
    transition: all ease 0.5s;
    opacity: 0;
  }
  .fotterClimp {
    .smallText {
      font-family: "Trap";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      /* or 31px */

      color: #c4c4c4;
      margin-top: 35px;
    }
    .btns {
      margin-top: 35px;
    }
    text-align: center;
    .button {
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
  .SliderProgras {
    width: 100%;
    height: 30px;

    background: rgba(217, 217, 217, 0.1);
    border: 1.19766px solid #c4c4c4;
    border-radius: 50px;

    .track {
      width: 50%;
      height: 100%;
      border-radius: 50px;

      background-color: #c73a33;
      display: flex;
      justify-content: end;
      align-items: center;
      position: relative;
      .ball {
        img {
          width: 100%;
        }

        position: absolute;
        right: -40px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
  }
  .section {
    width: 100%;
    padding: 60px;
  }
  .dropWraper {
    padding: 0 0px;
    position: relative;
    width: 100%;

    .dropBox {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      /* left: 0; */
      top: 100px;
      video {
        width: 450px;
      }
    }
    .dropBoxno {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      /* left: 0; */
      /* top: -; */
    }
  }
  .dotwrapper {
    // position: absolute;
    // height: ;

    margin: 60px 0;
    .lhHPLe {
      // position: absolute;
    }
  }
  .textBox {
    margin: 60px 0;
  }
  .textBoxs {
    margin: 60px 0;
    margin-bottom: 40px;
  }
  .updated {
    font-size: 32px !important;
  }
  /* overflow: hidden; */
  width: 100%;
  .texts {
    font-family: "Trap";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 24px;
    color: #c4c4c4;
  }
  .sliderBox {
    background: rgba(37, 37, 37, 0.9);
    border: 1px solid #c4c4c4;
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 24px;

    width: 100%;

    .sliderWrapper {
      position: relative;
      .rela {
        position: relative;
      }
      padding: 52px;
      /* width: 100%; */
      .current {
        color: #60d669;
      }
      .textBox {
        margin-top: 30px;
        width: 100%;
      }
      .stop-point {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 20px;
        top: 55px;
        /* background-color: transparent; */

        cursor: pointer;
        transform: translateX(-50%);
        font-family: "Trap";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        /* or 36px */

        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        /* color: #c4c4c4; */
      }
      .discound {
        padding: 5px 8px;
        padding-top: 8px;

        border-radius: 4px;
      }

      .stop-point[data-value="25"] {
        left: 23%;
      }

      .stop-point[data-value="50"] {
        left: 48%;
      }

      .stop-point[data-value="75"] {
        left: 73%;
      }
      .stop-point[data-value="100"] {
        left: 98%;
        white-space: nowrap;
      }
    }
    .active {
      background: #d9d9d91a;
    }
  }

  @media screen and (max-width: 998px) {
    .dropWraper {
      .dropBox {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        /* left: 0; */
        top: 100px;
        video {
          width: 330px;
        }
      }
    }
  }
  @media screen and (max-width: 760px) {
    .textBoxs {
      margin: 60px 0;
      margin-bottom: 40px;
    }
    .news {
      padding: 0 0px;
    }
    .subhead,
    .updated {
      font-size: 22px !important;
      line-height: 24px;
    }
    .dotwrapper {
      margin: 80px 0;
    }

    .opacity {
      transition: all ease 5s;
      /* display: none; */
      opacity: 0;
    }
    .pip {
      position: absolute;
      right: -30px;
      top: -20px;
      width: 220px;
    }
    .roll {
      position: absolute;
      left: -70px;
      bottom: -50px;
      width: 200px;
    }
    margin-top: 40px;
    padding-top: 80px;

    .SliderProgras {
      width: 100%;
      height: 15px;

      background: rgba(217, 217, 217, 0.1);
      border: 1.19766px solid #c4c4c4;
      border-radius: 50px;

      .track {
        width: 50%;
        height: 100%;
        border-radius: 50px;

        background-color: #c73a33;
        display: flex;
        justify-content: end;
        align-items: center;
        position: relative;
        .ball {
          img {
            width: 100%;
          }

          position: absolute;
          right: -20px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
    }

    .addPostion {
      background: #ebc43e;
      filter: blur(108.9px);
      width: 200px;
      height: 200px;
      top: 200px;
      left: 25%;
    }
    .texts {
      font-size: 14px;
      line-height: 130%;

      margin-top: 25px;
    }

    .section {
      width: 100%;
      padding: 00px 0px;
      .cOVOmM,
      .dBtiZQ {
        overflow: hidden;
        padding: 0px;
        border: none;
        backdrop-filter: blur(0px);
        border-radius: 24px;
      }
    }

    .dropWraper {
      position: relative;
      width: 99%;
      padding: 30px 0px;
      border: 1px solid #fff;

      background: rgba(37, 37, 37, 0.9);
      border: 0.511144px solid #c4c4c4;
      backdrop-filter: blur(5.11144px);
      margin-bottom: 40px;
      .subhead {
        padding: 0 15px;
      }
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 12.2675px;
      .dropBox {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        /* left: 0; */
        top: 110px;
        video {
          width: 300px;
        }
      }
      .dotwrapper {
        margin: 40px 0;
      }

      .dropBoxno {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        top: 90px;
        video {
          width: 300px;
        }
      }
    }

    .sliderBox {
      background: transparent;
      border: none;
      backdrop-filter: blur(0px);
      border-radius: 24px;
      width: 100%;
      margin-bottom: 60px;
      .sliderWrapper {
        position: relative;
        .rela {
          position: relative;
        }
        padding: 2px 20px;
        .stop-point {
          position: absolute;
          top: 35px;
          background-color: transparent;
          border-radius: 50%;
          cursor: pointer;
          transform: translateX(-50%);
          font-family: "Trap";
          font-style: normal;
          font-weight: 400;
          font-size: 9px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: capitalize;
          gap: 10px;
          color: #fff !important;
        }
        .discound {
          padding: 5px 8px;
          padding-top: 8px;
          width: 50px;
          white-space: pre-line;
          border-radius: 4px;
        }
        .current {
          white-space: pre-line;
          width: 60px;
          color: #60d669;
        }
        .active {
          background: #d9d9d91a;
        }

        .stop-point[data-value="25"] {
          left: 21%;
        }

        .stop-point[data-value="50"] {
          left: 46%;
        }

        .stop-point[data-value="75"] {
          left: 71%;
        }
        .stop-point[data-value="100"] {
          left: 96%;
        }
      }
    }
    .textBox {
      width: 100%;
    }
    .fotterClimp {
      .smallText {
        font-size: 16px;
        margin-top: 28px;
      }
      .btns {
        margin-top: 28px;
      }
      text-align: center;
      .button {
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
  }
`;
export default ClaimCoupon;
