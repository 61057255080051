import React, { useEffect, useState } from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
  :before {
    content: "";
    position: absolute;
    background: #5a5a5a;
    height: 16px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100px;
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: rgba(199, 58, 51, 1);
    height: 16px;
    border-radius: 100px;

    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    margin-top: 40px;

    :before {
      height: 7px;
    }
    :after {
      height: 7px;
    }
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ step }) =>
    step === "completed" ? "rgba(199, 58, 51, 1)" : "#5a5a5a"};
  transition: 0.4s ease;
  border: 3px solid
    ${({ step }) =>
      step === "completed" ? "rgba(199, 58, 51, 1)" : "rgba(199, 58, 51, 1)"};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 28px;
    height: 28px;
    border: 3px solid
      ${({ step }) =>
        step === "completed" ? "rgba(199, 58, 51, 1)" : "rgba(199, 58, 51, 1)"};
  }
`;

const StepCount = styled.span`
  margin-top: 4px;
  font-size: 19px;
  color: #f3e7f3;
  @media (max-width: 760px) {
    font-size: 16px;
  }
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 768px) {
  }
`;

const StepLabel = styled.span`
  font-size: 19px;
  color: #fff;
  @media (max-width: 600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 100px;

  @media screen and (max-width: 768px) {
  }
`;

const ButtonStyle = styled.button`
  border-radius: 4px;
  border: 0;
  background: rgba(199, 58, 51, 1);
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
  width: 90px;
  :active {
    transform: scale(0.98);
  }
  :disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
  }
  @media screen and (max-width: 768px) {
  }
`;

const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

// const steps = [
//   {
//     label: 'Address',
//     step: 1,
//   },
//   {
//     label: 'Shipping',
//     step: 2,
//   },
//   {
//     label: 'Payment',
//     step: 3,
//   },
//   {
//     label: 'Summary',
//     step: 4,
//   },
// ]

const ProgressSteps = ({ steps, activeStep, setActiveStep }) => {
  //   const [activeStep, setActiveStep] = useState(1)


  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  // const width = 23

  return (
    <MainContainer>
      <StepContainer width={width}>
        {steps.map(({ step, label }) => (
          <StepWrapper key={step}>
            <StepStyle step={activeStep >= step ? "completed" : "incomplete"}>
              {activeStep > step ? (
                <CheckMark>L</CheckMark>
              ) : (
                <StepCount>{step}</StepCount>
              )}
            </StepStyle>
          </StepWrapper>
        ))}
      </StepContainer>
    </MainContainer>
  );
};

export default ProgressSteps;
