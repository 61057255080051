import React, { useContext } from "react";
import styled from "styled-components";
import assets from "../../assets/images";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../Contexts/GlobalContext";
const tasks = [
  {
    no: "01",
    content:
      "Share your collectible on Twitter and give it an interesting caption. Tag @celioindia and @emiwaytweets",
    tasks: "twitter",
    tittle: "Twitter Contest",
    img1: assets.logos.tweeter,
    img2: assets.logos.tweeter2,
    status: "success",
    url: "/task/twitter",
  },
  {
    no: "02",
    content:
      "Share your collectible on Instagram Story and give it an interesting caption. Tag @celioindia and @emiwaybantai",
    tasks: "intagram",
    tittle: "Instagram Contest ",

    img1: assets.logos.insta,
    img2: assets.logos.inta2,
    status: "pending",
    url: "/task/instagram",
  },
  {
    no: "03",
    content: "Share your collectible on Facebook and give it an interesting caption. Tag @celioindia and @emiwaybantai",
    tasks: "facebook",
    tittle: "Facebook Contest",

    img1: assets.logos.wplogo,
    img2: assets.logos.whatsapp2,
    status: "success",
    url: "/task/facebook",
  },
];

const Tasks = () => {
  const { userData, isclaimed } = useContext(GlobalContext);

  const navigate = useNavigate();
  return (
    <TaskWrapper>
      <div className="tasksWrapper">
        <table className="pc NOTABE">
      <div className="linet"></div>

          <tr className="tablehead">
            <th>S.no</th>
            <th>Contest task</th>
            <th></th>
            <th>Status</th>
          </tr>
          {tasks.map((data) => (
            <tr>
              <td>{data?.no}</td>
              <td>
                <div className="taskDetails">
                  <img src={data?.img1} alt="" />
                  <div className="testContent">{data?.content}</div>
                </div>
              </td>

              <td>
                {data.tasks === "intagram" ? (
                  <button
                    onClick={() => navigate(data.url)}
                    className={`Taskbtn ${data.tasks}`}
                    disabled={
                      userData.instagramTask || isclaimed ? true : false
                    }
                  >
                    Share
                    <img src={data?.img2} alt="" />
                  </button>
                ) : data.tasks === "twitter" ? (
                  <button
                    onClick={() => navigate(data.url)}
                    className={`Taskbtn ${data.tasks}`}
                    disabled={userData.twitterTask || isclaimed ? true : false}
                  >
                    Share
                    <img src={data?.img2} alt="" />
                  </button>
                ) : data.tasks === "facebook" ? (
                  <button
                    onClick={() => navigate(data.url)}
                    className={`Taskbtn ${data.tasks}`}
                    disabled={userData.facebookTask || isclaimed ? true : false}
                  >
                    Share
                    <img src={data?.img2} alt="" />
                  </button>
                ) : (
                  false
                )}
              </td>
              <td>
                {data.tasks === "intagram" ? (
                  <div
                    className={
                      userData.instagramTask
                        ? "statusBox success"
                        : "statusBox pending"
                    }
                  >
                    {userData.instagramTask ? "Completed" : "Pending"}
                  </div>
                ) : data.tasks === "twitter" ? (
                  <div
                    className={
                      userData.twitterTask
                        ? "statusBox success"
                        : "statusBox pending"
                    }
                  >
                    {userData.twitterTask ? "Completed" : "Pending"}
                  </div>
                ) : data.tasks === "facebook" ? (
                  <div
                    className={
                      userData.facebookTask
                        ? "statusBox success"
                        : "statusBox pending"
                    }
                  >
                    {userData.facebookTask ? "Completed" : "Pending"}
                  </div>
                ) : (
                  false
                )}
              </td>
            </tr>
          ))}
        </table>
        {tasks.map((data) => (
          <div className="taskMobil mobile TABE">
            <div className="wtappers">
              <div className="topSec">
                <div className="header">
                  <img src={data?.img1} alt="" />
                  <div className="testContent">{data?.tittle}</div>
                </div>

                {data.tasks === "intagram" ? (
                  <div
                    className={
                      userData.instagramTask
                        ? "statusBox success"
                        : "statusBox pending"
                    }
                  >
                    {userData.instagramTask ? "Completed" : "Pending"}
                  </div>
                ) : data.tasks === "twitter" ? (
                  <div
                    className={
                      userData.twitterTask
                        ? "statusBox success"
                        : "statusBox pending"
                    }
                  >
                    {userData.twitterTask ? "Completed" : "Pending"}
                  </div>
                ) : data.tasks === "facebook" ? (
                  <div
                    className={
                      userData.facebookTask
                        ? "statusBox success"
                        : "statusBox pending"
                    }
                  >
                    {userData.facebookTask ? "Completed" : "Pending"}
                  </div>
                ) : (
                  null
                )}
              </div>
              <div className="MidSec">
                <div className="testContents">{data?.content}</div>
              </div>
              <div className="bottomSec">
              {data.tasks === "intagram" ? (
                  <button
                    onClick={() => navigate(data.url)}
                    className={`Taskbtn ${data.tasks}`}
                    disabled={
                      userData.instagramTask || isclaimed ? true : false
                    }
                  >
                    Share
                    <img src={data?.img2} alt="" />
                  </button>
                ) : data.tasks === "twitter" ? (
                  <button
                    onClick={() => navigate(data.url)}
                    className={`Taskbtn ${data.tasks}`}
                    disabled={userData.twitterTask || isclaimed ? true : false}
                  >
                    Share
                    <img src={data?.img2} alt="" />
                  </button>
                ) : data.tasks === "facebook" ? (
                  <button
                    onClick={() => navigate(data.url)}
                    className={`Taskbtn ${data.tasks}`}
                    disabled={userData.facebookTask || isclaimed ? true : false}
                  >
                    Share
                    <img src={data?.img2} alt="" />
                  </button>
                ) : (
                  false
                )}
               
              </div>
            </div>
          </div>
        ))}
      </div>
    </TaskWrapper>
  );
};
const TaskWrapper = styled.div`
  background: rgba(37, 37, 37, 0.9);
  border: 1px solid #c4c4c4;
  backdrop-filter: blur(10px);

  border-radius: 24px;

  .tasksWrapper {
    padding: 30px;
    position: relative;
  }
 
  .Taskbtn {
    display: flex;
    width: 125px;
    gap: 10px;
    background-color: #c4c4c4;
    border-radius: 10px;
    padding: 12px 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: all ease 0.3s;

    :disabled {
      background: rgba(255, 255, 255, 0.55);
      color: #e6e6e6;
      cursor: auto;
     
    }
  }
  .twitter {
    background-color: #4a99e9;
    :hover {
      background: #3d80c3;
    }
    :disabled {
      background: rgba(255, 255, 255, 0.55);
      color: #e6e6e6;
      cursor: auto;
     
    }
  }
  .intagram {
    background-color: #4193ef;
    :hover {
      background: #3975ea;
    }
    :disabled {
      background: rgba(255, 255, 255, 0.55);
      color: #e6e6e6;
      cursor: auto;
     
    }
  }
  .facebook {
    background-color: #1877F2;
    :hover {
      background: #1052a8;
    }
    :disabled {
      background: rgba(255, 255, 255, 0.55);
      color: #e6e6e6;
      cursor: auto;
     
    }
  }
  .linet{
    position: absolute;
    top: 45px;
    width: 100%;
    background:#555;
    height: 1px;

  }
  table {
    position: relative;
    width: 100%;
    /* border-collapse: collapse; */
    border-spacing: 30px 0;
    .tablehead {
      border-bottom: 1px solid #555;
    }
    th {
      font-family: "Trap";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;

      padding-bottom: 20px;
    }
    tr {
      text-align: left;

      /* border-bottom: 2px solid black; */
    }
    .statusBox {
      color: #c4c4c4;
    }
    .success {
      color: #60d669;
    }
    .pending {
      color: #fcd449;
    }
    td {
      font-family: "Trap";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      padding: 30px 0;
    }
  }
  .taskDetails {
    display: flex;
    gap: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }
  @media screen and (max-width: 999px) {
  .TABE{
    display: block;
  }
  .NOTABE{
    display: none;
  }
}
  @media screen and (max-width: 999px) {
    .tasksWrapper {
      padding: 0px;
    }
    background: transparent;
    border: none;
    backdrop-filter: blur(0px);

    border-radius: 24px;

    .taskMobil {
      background: rgba(37, 37, 37, 0.9);
      border: 1px solid #c4c4c4;
      backdrop-filter: blur(10px);

      border-radius: 10px;
      margin-bottom: 20px;
    }
    .wtappers {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .topSec {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;

          .testContent {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            /* identical to box height, or 164% */

            text-align: center;

            color: #c4c4c4;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      .MidSec {
        .testContent {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 140%;
          color: #ffffff;
        }
      }
      .statusBox {
        color: #c4c4c4;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 6px;
        padding: 7px 9px;
        font-weight: 500;
        font-size: 12px;
      }
      .success {
        color: #60d669;
      }
      .pending {
        color: #fcd449;
      }
      .testContents {
        color: #fff;
        font-size: 14px;
        line-height: 140%;
      }
      .bottomSec {
        .Taskbtn {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background-color: #c4c4c4;
          border-radius: 10px;
          padding: 9px 20px;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          color: #fff;
          width: 100% !important;
          img {
            width: 15.14px;
            height: 12.3px;
          }
          cursor: pointer;

          .success {
            background-color: #2daae1;
          }
          .pending {
            background-color: #50aaff;
          }

          :disabled {
            background: rgba(255, 255, 255, 0.55);
            color: #e6e6e6;
            cursor: auto;
          }
        }
        .twitter {
          background-color: #4a99e9;
          :hover {
            background: #3d80c3;
          }
          :disabled {
            background: rgba(255, 255, 255, 0.55);
            color: #e6e6e6;
            cursor: auto;
          }
        }
        .intagram {
          background-color: #4193ef;
          :hover {
            background: #3975ea;
          }
          :disabled {
            background: rgba(255, 255, 255, 0.55);
            color: #e6e6e6;
            cursor: auto;
          }
        }
        .facebook {
          background-color: #1877F2;
          :hover {
            background: #54bd5c;
          }
          :disabled {
            background: rgba(255, 255, 255, 0.55);
            color: #e6e6e6;
            cursor: auto;
          }
        }
      }
    }
  }
`;

export default Tasks;
