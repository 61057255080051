import React from 'react'
import styled from "styled-components";


const BgCard = ({ children }) => {
  return (
    <BGCardWrapper>{ children }</BGCardWrapper>
  )
}
const BGCardWrapper = styled.div`
overflow: hidden;
padding: 50px;
border: 1px solid #C4C4C4;
backdrop-filter: blur(10px);
border-radius: 24px;
@media screen and (max-width: 768px) {
  border-radius: 12px;

  padding: 40px 25px;
}
`;
export default BgCard