import styled from "styled-components";
import assets from "../../assets/images";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import terms from "./data";
import { useEffect, useState } from "react";

const TandC = () => {
  const navigate = useNavigate();
  const [textState, setTextSrate] = useState({ state: false, value: "" });

  const handleDownloadClick = () => {
    const url = assets.images.nft;
    const a = document.createElement("a");
    a.href = url;
    a.download = "nft.gif";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = Number(entry.target.id.split('-')[1]);
            setSelectedItem(id);
          }
        });
      },
      { rootMargin: '-50px' }
    );
    terms.forEach((item) => {
      const target = document.querySelector(`#item-${item.id}`);
      if (target) {
        observer.observe(target);
      }
    });
  }, [terms]);

  
  
  
  
  
  
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, height: "auto", transition: { duration: 0.8 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <CouponWrapper>
        <div className="section">
          <div className="container">
            <div className="navigation">
              <img
                src={assets.logos.arrow}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              <div className="naveText">Term of Services</div>
            </div>

            <div className="temBox">
              <div className="lefts D-NON">
                {terms.map((item) => {
                  return (
                    <div className="leftItems ">
                      <HashLink
                      key={item.id}
                        smooth
                        onClick={() => handleItemClick(item.id)}
                        // onClick={() =>
                        //   setTextSrate({
                        //     state: true,
                        //     value: item.title.toLocaleLowerCase(),
                        //   })
                        // }
                        className={
                          selectedItem === item.id 
                            ? "red"
                            : "light"
                        }
                        to={`#item-${item.id}`}
                      >
                        {item.title}
                      </HashLink>
                    </div>
                  );
                })}
              </div>

              <div className="right">
                {terms.map((item) => {
                  return (
                    <div
                      className="rightItems"
                     id={`item-${item.id}`}
                      key={item.id}
                    >
                      <div className="termHeading">{item.title}</div>
                      {item.discription.map((dis) => {
                        return <div className="termPara">{dis}</div>;
                      })}
                      <ul>
                        {item.list.map((list) => {
                          return (
                            <li>
                              <div className="termPara">{list}</div>
                            </li>
                          );
                        })}
                      </ul>
                      <div className="termCapsLock"></div>

                      {/*item?.button ? (
                        <div
                          className=" downbtn "
                          onClick={handleDownloadClick}
                        >
                          Download pdf
                        </div>
                      ) : null*/}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </CouponWrapper>
    </motion.div>
  );
};

const CouponWrapper = styled.div`
  margin-top: 110px;
  .temBox {
    display: flex;
    margin-top: 30px;
    background: rgba(37, 37, 37, 0.9);
    border: 1px solid #c4c4c4;
    backdrop-filter: blur(10px);

    border-radius: 24px;
    height: 85vh;
    .rightItems {
      border-bottom: 1px solid #555;
      padding-bottom: 60px;
      padding-top: 30px;
    }
    .right {
      font-family: "trap";
      padding: 30px 30px;
      overflow-y: scroll;

      .termHeading {
        font-weight: 700;
        font-size: 32px;
        line-height: 140%;
        margin-bottom: 20px;
      }
      .termPara {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        margin-bottom: 30px;
      }
      .termCapsLock {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        margin-bottom: 30px;
      }
    }

    .lefts {
      display: flex;

      min-width: 270px;
      padding: 30px 30px;
      flex-direction: column;
      overflow-y: scroll;
      .leftItems {
        font-size: 18px !important;
        font-weight: 600;
        padding: 15px 8px;
      }
      a {
        /* color: #fff; */
        text-decoration: none;
      }
    }
    .D-NON{
      display: none;
    }
  }
  .red {
    color: rgba(199, 58, 51, 1);
  }
  .light {
    color: #fff;
  }
  .downbtn {
    cursor: pointer;
    color: rgba(199, 58, 51, 1) !important;
  }
  .navigation {
    display: flex;
    font-size: 36px;
    line-height: 100%;
    font-weight: 800;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 1350px) {
    .right {
      // flex: 4;
      font-family: "trap";
      padding: 30px 30px;
      overflow-y: scroll;

      .termHeading {
        font-weight: 700;
        font-size: 28px;
        line-height: 140%;
        margin-bottom: 20px;
      }
      .termPara {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: 998px) {
    .temBox {
      .lefts {
        display: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .navigation {
      margin-top: 60px;
      display: flex;
      font-size: 18px;
      line-height: 100%;
      font-weight: 800;
      gap: 12px;
      justify-content: flex-start;
      align-items: center;
      z-index: 2;
      position: relative;
      img {
        width: 18px;
      }
    }
    .temBox {
      .rightItems {
        border-bottom: 1px solid #555;
        padding-bottom: 30px;
        padding-top: 30px;
      }
      .right {
        // flex: 4;
        font-family: "trap";
        padding: 30px 15px;
        overflow-y: scroll;

        .termHeading {
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          margin-bottom: 20px;
        }
        .termPara {
          font-weight: 400;
          font-size: 13px;
          line-height: 140%;
          margin-bottom: 20px;
        }
      }
      .lefts {
        display: none;
      }
    }
  }
`;
export default TandC;
