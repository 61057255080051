import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import assets from "../../assets/images";
import DotBox from "../../Components/DotBox/DotBox";
import GlobalContext from "../../Contexts/GlobalContext";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import FAQ from "../../Components/FAQ/FAQ";
import Marquee from "../../Components/Marquee/Marquee";
import Button from "../../Components/Utils/Button/Button";
import NftLIst from "../../assets/NFT";
import NFFLIST from "../../assets/NFT/nfts";
import { saveAs } from "file-saver";
import vedio from "../../assets/videos/nft/1.mp4";

const MyNft = () => {
  const { minted, isConnected, userData, checkMintedOrNot, user, indexedNFt,indexNFT } =
    useContext(GlobalContext);

  const navigate = useNavigate();
  const [myNft, setMyneft] = useState();

  useEffect(() => {

    checkMintedOrNot();
    if (isConnected === false) {
      navigate("/");
    }
    user();
    if (userData) {
      if (minted === false || isConnected === false) {
        navigate("/");
      }
    }

    setMyneft(NFFLIST[userData.tokenId - 1]);

    // eslint-disable-next-line
  }, [isConnected]);

  const handleDownloadClick = () => {
    const url = indexedNFt?.balances[0]?.tokenMetadata?.image;
    console.log(url)

    saveAs(url, "Celio_Collectible.mp4");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, height: "auto", transition: { duration: 0.8 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <CouponWrapper>
        <div className="ff">
          <div className="section">
            <div className="container">
              <div className="navigation">
                <img
                  src={assets.logos.arrow}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
                <div className="naveText">My NFTs</div>
              </div>
              <div className="myCard">
                <div className="left">
                  {/* <img src={myNft?.image} alt="" />*/}
                  <video
                    className="img"
                    autoPlay
                    playsInline
                    loop
                    muted
                    src={indexedNFt?.balances[0]?.tokenMetadata?.image}
                  ></video>
                </div>
                <div className="right">
                  <div className="rightTop">
                    <div className="heading">Emiway Bantai Collectible</div>
                    <div className="par">
                      Introducing the exclusive Emiway Bantai Collectible - a
                      unique digital asset that showcases your love for Emiway's
                      music and style! This limited edition NFT is a must-have
                      for fans and collectors alike.
                    </div>
                  </div>
                  <div className="righyBottom">
                    <div className="heading"> About the Collectible</div>
                    <div className="par">
                      This Emiway Bantai Collectible is a one-of-a-kind NFT,
                      featuring eye-catching artwork inspired by Emiway's iconic
                      persona and vibrant music. As a proud owner of this
                      collectible, you'll stand out as a true fan while also
                      getting access to exciting discounts on Emiway Merch by
                      Celio.
                    </div>
                  </div>

                  <Button
                    text={"Download Collectible"}
                    click={handleDownloadClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mark">
          <Marquee />
        </div>
        <div className="faqa">
          <FAQ />
        </div>
        <DotBox />
      </CouponWrapper>
    </motion.div>
  );
};

const CouponWrapper = styled.div`
  .ff {
    position: relative;
    position: relative;
    margin-top: 60px;
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pip {
    position: absolute;
    right: -50px;
    top: -120px;
    width: 500px;
  }
  .roll {
    position: absolute;
    left: -100px;
    bottom: -80px;
    width: 500px;
  }
  .faqa {
    margin-bottom: 60px;
  }

  .mark {
    margin-bottom: 80px;
  }
  .section {
    width: 100%;
    padding: 60px;
  }
  .navigation {
    display: flex;
    font-size: 36px;
    line-height: 100%;
    font-weight: 800;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
  }
  .myCard {
    margin-top: 50px;
    background: rgba(37, 37, 37, 0.9);
    border: 1px solid #c4c4c4;
    backdrop-filter: blur(10px);
    border-radius: 24px;
    display: flex;
    padding: 35px;
    gap: 24px;

    .left {
      width: 400px;

      .img {
        width: 350px;

        height: auto;
        /* object-fit: cover; */
      }
    }
    .right {
      padding-top: 20px;
      padding-bottom: 20px;
      .rightTop {
      }

      .righyBottom {
        margin-top: 50px;
      }
      .heading {
        font-family: "Trap";
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 100%;
      }
      .par {
        font-weight: 500;
        font-size: 22px;
        line-height: 130%;
      }
    }
  }
  @media screen and (max-width: 998px) {
    .myCard {
      flex-direction: column;
      .left {
        margin: 0 auto;
        height: 100%;

        .img {
          margin: 0 auto;
          width: 350px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .pip {
      position: absolute;
      right: -30px;
      top: -20px;
      width: 220px;
    }
    .roll {
      position: absolute;
      left: -70px;
      bottom: -50px;
      width: 200px;
    }

    .section {
      width: 100%;
      padding: 00px 0px;
    }

    .navigation {
      margin-top: 60px;
      display: flex;
      font-size: 24px;
      line-height: 100%;
      font-weight: 800;
      gap: 15px;
      justify-content: flex-start;
      align-items: center;
      z-index: 2;
      position: relative;
      img {
        width: 25px;
      }
    }
    .myCard {
      margin-top: 30px;

      flex-direction: column;
      padding: 20px;
      margin-bottom: 60px;

      .left {
        width: 100%;
        .img {
          width: 85%;
          margin: 0 auto;
          height: 100%;
        }
      }
      .right {
        padding-top: 20px;
        padding-bottom: 20px;
        /* text-align: center; */
        .rightTop {
        }

        .righyBottom {
          margin: 0 auto;
          margin-top: 50px;
        }
        .heading {
          font-family: "Trap";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          text-align: left;

          line-height: 120%;
        }
        .par {
          font-weight: 400;
          margin-top: 15px;
          font-size: 16px;
          line-height: 130%;
        }
      }
    }
  }
`;
export default MyNft;
